import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";

// страница отображения логов
export class LogsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startupLog: "",
      afuLog: "",
    };
    this.get = this.get.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  get() {
    new Load(
      this.context,
      "Получение стартового лога",
      "/api/admin/log-startup"
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ startupLog: data });
      });
    new Load(this.context, "Получение лога AFU", "/api/admin/log-afu")
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ afuLog: data });
      });
  }
  render() {
    return (
      <div>
        <h1>Логи</h1>
        <button onClick={() => this.get()}>Обновить</button>
        <ShowLog name="Стартовый лог" log={this.state.startupLog} />
        <ShowLog name="Лог AFU" log={this.state.afuLog} />
      </div>
    );
  }
}
LogsPage.contextType = NotificationContext;

// функция рендера лога
function ShowLog(props) {
  return (
    <details style={{ userSelect: "none" }}>
      <summary style={{ outline: "none" }}>{props.name}</summary>
      <pre
        style={{
          maxHeight: "300px",
          overflow: "overlay",
          border: "1px dashed #000",
          padding: "10px",
          userSelect: "text",
        }}
      >
        {props.log}
      </pre>
    </details>
  );
}
