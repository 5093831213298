import React from "react";
import { DataContext, NotificationContext, ZeroID } from "base/context";
import { Load } from "base/load";
import {
  ChoiseEmployeePicker,
  ChoiseInnerDataPicker,
  DateTimePicker,
  MultiChoisePicker,
} from "base/service";

// компонент редактора обращений (оператор/модератор)
export class OperatorAppealEditRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { data: {}, tags: [] },
      move: false,
      press: false,
      cord: { x: 700, y: 100 },
      diffCord: { x: 0, y: 0 },
      districts: [],
      microdistricts: [],
    };
    this.save = this.save.bind(this);
    this.paste = this.paste.bind(this);
    this.checkMove = this.checkMove.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.makeMove = this.makeMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  componentDidMount() {
    const cord = JSON.parse(localStorage.getItem("appeal_editor_cord"));
    if (cord && cord.x !== undefined && cord.y !== undefined) {
      this.setState({ cord: cord });
    }
    this.get(this.props.id);
    this.loadInfo();
  }
  componentWillUnmount() {
    localStorage.setItem("appeal_editor_cord", JSON.stringify(this.state.cord));
  }
  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.get(this.props.id);
    }
  }
  get(id) {
    if (id === "new") {
      this.setState({
        data: {
          registration: new Date().toJSON(),
          tags: [],
          data: {
            address: "",
            residence: "",
            fullname: "",
            district: "",
            microdistrict: "",
            phone: "",
            reason: "",
            object_name: "",
          },
          operator_id: ZeroID,
          stranded_id: ZeroID,
          registrar_type: 1,
          accepted: 1,
          note: "",
        },
      });
      return;
    }
    new Load(
      this.context,
      "Получение обращения",
      "/api/operator/get",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        data.tags = data.tags || [];
        this.setState({ data: data });
      });
  }
  save() {
    const id = this.state.data.id;
    new Load(
      this.context,
      "Сохранение обращения",
      "/api/operator/" + (id ? "update" : "create"),
      this.state.data
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.props.close());
  }
  update(fieldName, value) {
    let tmp = { ...this.state.data };
    tmp[fieldName] = value;
    this.setState({ data: tmp });
  }
  updateData(fieldName, value) {
    let tmp = { ...this.state.data.data };
    tmp[fieldName] = value;
    this.update("data", tmp);
  }
  checkMove() {
    this.setState((state) => ({ move: !state.move }));
  }
  makeMove(e) {
    if (this.state.press) {
      this.setState((state) => ({
        cord: {
          x: Math.max(e.pageX - state.diffCord.x, 15),
          y: Math.max(e.pageY - state.diffCord.y, 15),
        },
      }));
    }
  }
  mouseDown(e) {
    this.setState((state) => ({
      diffCord: { x: e.pageX - state.cord.x, y: e.pageY - state.cord.y },
      press: true,
    }));
  }
  mouseUp() {
    this.setState({ press: false });
    localStorage.setItem("appeal_editor_cord", JSON.stringify(this.state.cord));
  }
  paste() {
    try {
      let tmp = localStorage.getItem("appeal_editor_data");
      tmp = JSON.parse(tmp);
      let data = { ...this.state.data };
      data.data = tmp.data;
      data.note = tmp.note;
      data.tags = tmp.tags;
      this.setState({ data: data });
    } catch {}
  }
  loadInfo() {
    new Load(
      this.context,
      "Загрузка группы районов",
      "/api/operator/district",
      {
        code: "dist",
      }
    )
      .Load()
      .then((data) => {
        let districts = [];
        for (let key in data) {
          districts.push({ code: key, count: data[key] });
        }
        districts.sort((a, b) =>
          a.count > b.count ? -1 : a.count < b.count ? 1 : 0
        );
        this.setState({ districts: districts });
      });
    new Load(
      this.context,
      "Загрузка группы микрорайонов",
      "/api/operator/district",
      {
        code: "micro",
      }
    )
      .Load()
      .then((data) => {
        let microdistricts = [];
        for (let key in data) {
          microdistricts.push({ code: key, count: data[key] });
        }
        microdistricts.sort((a, b) =>
          a.count > b.count ? -1 : a.count < b.count ? 1 : 0
        );
        this.setState({ microdistricts: microdistricts });
      });
  }
  render() {
    const data = this.state.data;
    const idName =
      data.id !== "new" && data.id !== undefined ? data.id : "Новое";
    const number = data.number || "Новое";
    const position = {
      top: "" + this.state.cord.y + "px",
      left: "" + this.state.cord.x + "px",
    };
    const moderation = this.props.moderation || false;
    return (
      <div
        className="editor"
        style={position}
        mousemove="mouseMove"
        mouseup="move = false"
      >
        <table style={{ marginTop: "15px" }}>
          <tbody>
            <tr>
              <td className="info">ID</td>
              <td className="data">{idName}</td>
            </tr>
            <tr>
              <td className="info">Номер обращения</td>
              <td className="data">{number}</td>
            </tr>
            <tr>
              <td className="info">Время обращения</td>
              <td className="data">
                <DateTimePicker
                  value={data.registration}
                  onChange={(e) => this.update("registration", e)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Адрес нарушения</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.data.address}
                  onChange={(e) => this.updateData("address", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Адрес проживания</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.data.residence}
                  onChange={(e) => this.updateData("residence", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">ФИО заявителя</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.data.fullname}
                  onChange={(e) => this.updateData("fullname", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Район</td>
              <td className="data">
                <input
                  list="district"
                  className="stdin"
                  value={data.data.district}
                  onChange={(e) => this.updateData("district", e.target.value)}
                />
                <datalist id="district">
                  {this.state.districts.map((e) => (
                    <option value={e.code} key={e.code} />
                  ))}
                </datalist>
                {/* <DistSelecter
                  info={this.state.districts}
                  value={data.data.district}
                  onChange={(e) => this.updateData("district", e)}
                /> */}
              </td>
            </tr>
            <tr>
              <td className="info">Микрорайон</td>
              <td className="data">
                <input
                  list="microdistrict"
                  className="stdin"
                  value={data.data.microdistrict}
                  onChange={(e) =>
                    this.updateData("microdistrict", e.target.value)
                  }
                />
                <datalist id="microdistrict">
                  {this.state.microdistricts.map((e) => (
                    <option value={e.code} key={e.code} />
                  ))}
                </datalist>
                {/* <DistSelecter
                  info={this.state.microdistricts}
                  value={data.data.microdistrict}
                  onChange={(e) => this.updateData("microdistrict", e)}
                /> */}
              </td>
            </tr>
            <tr>
              <td className="info">Телефон заявителя</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.data.phone}
                  onChange={(e) => this.updateData("phone", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Причина звонка</td>
              <td className="data">
                <textarea
                  style={{ height: "80px" }}
                  className="stdin"
                  value={data.data.reason}
                  onChange={(e) => this.updateData("reason", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Оператор</td>
              <td className="data">
                <ChoiseEmployeePicker
                  value={data.operator_id}
                  onChange={(e) => this.update("operator_id", e)}
                  type="operator"
                  show={true}
                  className="stdin"
                />
              </td>
            </tr>
            <tr>
              <td className="info">Инспектор которому была передана заявка</td>
              <td className="data">
                <ChoiseEmployeePicker
                  value={data.stranded_id}
                  onChange={(e) => this.update("stranded_id", e)}
                  type="inspector"
                  show={true}
                  className="stdin"
                />
              </td>
            </tr>
            <tr>
              <td className="info">Тип подавшего заявку</td>
              <td className="data">
                <ChoiseInnerDataPicker
                  value={data.registrar_type}
                  type="registrar"
                  className="stdin"
                  onChange={(e) => this.update("registrar_type", parseInt(e))}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Метки жалобы (тип загрязнения и т.п.)</td>
              <td className="data">
                <DataContext.Consumer>
                  {(innerData) => (
                    <MultiChoisePicker
                      value={data.tags}
                      info={innerData.tagsToFilter}
                      onChange={(e) => this.update("tags", e)}
                    />
                  )}
                </DataContext.Consumer>
              </td>
            </tr>
            <tr>
              <td className="info">Прошла ли заявка модерацию</td>
              <td className="data">
                <ChoiseInnerDataPicker
                  value={data.accepted}
                  type="accepted"
                  className="stdin"
                  onChange={(e) => this.update("accepted", parseInt(e))}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Заметка оператора</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.note}
                  onChange={(e) => this.update("note", e.target.value)}
                />
              </td>
            </tr>
            {moderation ? (
              <>
                <tr>
                  <td className="info">Широта</td>
                  <td className="data">
                    <input
                      type="number"
                      step={0.01}
                      className="stdin"
                      value={data.data.lat}
                      onChange={(e) =>
                        this.updateData("lat", parseFloat(e.target.value))
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="info">Долгота</td>
                  <td className="data">
                    <input
                      type="number"
                      step={0.01}
                      className="stdin"
                      value={data.data.lon}
                      onChange={(e) =>
                        this.updateData("lon", parseFloat(e.target.value))
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="info">Наименование объекта</td>
                  <td className="data">
                    <input
                      className="stdin"
                      value={data.data.object_name}
                      onChange={(e) =>
                        this.updateData("object_name", e.target.value)
                      }
                    />
                  </td>
                </tr>
              </>
            ) : null}
            <tr className="centred">
              <td>
                <button
                  style={{ fontSize: "large", fontWeight: "bold" }}
                  onClick={this.paste}
                >
                  Вставить
                </button>
              </td>
              <td>
                <button
                  style={{ fontSize: "large", fontWeight: "bold" }}
                  className="text-green"
                  onClick={this.save}
                >
                  Сохранить
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: this.state.move ? "block" : "none",
            backgroundImage: "url(/img/move.png)",
          }}
          className="white"
          onMouseMove={this.makeMove}
          onMouseDown={this.mouseDown}
          onMouseUp={this.mouseUp}
        ></div>
        <button
          className="move"
          title="нажми и передвигай это окно"
          onClick={this.checkMove}
          style={{
            background: this.state.move ? "orange" : "#efefef",
          }}
        >
          O
        </button>
        <button
          onClick={() =>
            this.setState({ cord: { x: 700, y: 100 }, move: false })
          }
          className="reset"
          title="сбрасывает положение окна"
        >
          R
        </button>
        <button
          onClick={this.props.close}
          className="close"
          title="закрывает окно"
        >
          X
        </button>
      </div>
    );
  }
}
OperatorAppealEditRaw.contextType = NotificationContext;

// function DistSelecter(props) {
//   const f = props.info
//     ? props.info
//         .filter((e) => e.code.length > 0)
//         .filter(
//           (e) =>
//             (props.value
//               ? e.code.toLowerCase().indexOf(props.value.toLowerCase())
//               : 1) > -1
//         )
//         .filter((_, i) => i < 3)
//     : [];
//   return (
//     <ul className="district">
//       {f.map((e) => (
//         <li key={e.code} onClick={() => props.onChange(e.code)}>
//           {e.code}
//         </li>
//       ))}
//     </ul>
//   );
// }
