import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";
import { InspectorAppealViewHead } from "../inspector/view";
import { Chart } from "chart.js";

// страница анализа данных
export class AnalyzerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        inspector_ids: [],
        registrar_type: [],
        accepted: [1],
        tags: [],
        phase_id: [],
        phase_public_id: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
      data: {},
      timeOptions: [
        { start: 0, end: 5, name: "ночь" },
        { start: 6, end: 10, name: "утро" },
        { start: 11, end: 17, name: "день" },
        { start: 18, end: 21, name: "вечер" },
        { start: 22, end: 23, name: "ночь" },
      ],
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }
  get() {
    new Load(this.context, "анализ", "/api/analyzer/time", {
      filter: this.state.filter,
      time_options: this.state.timeOptions,
    })
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data,
        });
      });
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
  }
  render() {
    return (
      <div>
        <table className="view">
          <thead className="triple analyzer">
            <InspectorAppealViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
        </table>
        <hr />
        <TimeOption
          value={this.state.timeOptions}
          onChange={(e) => this.setState({ timeOptions: e })}
        />
        <button onClick={this.get} className="text-green">
          Проанализировать
        </button>
        <hr />
        <Graph
          value={this.state.data.week_day}
          func={(e) => analyzeWeekDay(e)}
          head="По дням недели"
          saveName="график дней недели.png"
          type="bar"
        />
        <Graph
          value={this.state.data.month}
          func={(e) => analyzeMonth(e)}
          head="По дням месяца"
          saveName="месячный график.png"
          type="line"
        />
        <Graph
          value={this.state.data.week}
          func={(e) => analyzeWeek(e)}
          head="По номеру недели"
          saveName="недельный график.png"
          type="line"
        />
      </div>
    );
  }
}
AnalyzerPage.contextType = NotificationContext;

// компонет для выбора диапазона времени для анализа
class TimeOption extends React.Component {
  update(ind, fieldName, value) {
    const options = this.props.value.map((e, i) =>
      i !== ind ? e : { ...e, [fieldName]: value }
    );
    this.props.onChange(options);
  }
  delete(ind) {
    const options = this.props.value.filter((_, i) => i !== ind);
    this.props.onChange(options);
  }
  add() {
    const options = [...this.props.value, { start: 0, end: 0, name: "" }];
    this.props.onChange(options);
  }
  render() {
    return (
      <table>
        <thead>
          <tr>
            <td>
              <button onClick={() => this.add()}>+</button>
            </td>
            <td>Название</td>
            <td>Начало</td>
            <td>Конец</td>
          </tr>
        </thead>
        <tbody>
          {this.props.value.map((e, ind) => (
            <tr key={e + ind}>
              <td>
                <button onClick={() => this.delete(ind)}>-</button>
              </td>
              <td>
                <input
                  value={e.name}
                  onChange={(e) => this.update(ind, "name", e.target.value)}
                />
              </td>
              <td>
                <input
                  value={e.start}
                  onChange={(e) =>
                    this.update(ind, "start", parseInt(e.target.value))
                  }
                />
              </td>
              <td>
                <input
                  value={e.end}
                  onChange={(e) =>
                    this.update(ind, "end", parseInt(e.target.value))
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

// функция выбора рандомного цвета
function getRandomColor() {
  let letters = "0123456789ABCDEF".split("");
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// компонет для отрисовки графика
class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.graph = null;
    this.graphRef = React.createRef();
  }
  componentDidMount() {
    this.graph = new Chart(this.graphRef.current, {
      type: this.props.type,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value !== prevProps.value) {
      this.graph.data = this.props.func(this.props.value);
      this.graph.update();
    }
  }
  render() {
    let chartSaver = React.createRef();
    const save = () => {
      chartSaver.current.href = this.graph.toBase64Image();
      chartSaver.current.download = this.props.saveName;
      chartSaver.current.click();
    };
    return (
      <div>
        {this.props.head}
        <button onClick={() => save()} style={{ marginLeft: "10px" }}>
          Скачать
        </button>
        <div style={{ position: "relative", height: "500px", width: "1000px" }}>
          <canvas ref={this.graphRef}></canvas>
        </div>
        <a
          ref={chartSaver}
          download="img.png"
          style={{ visibility: "hidden" }}
          href={"/#/"}
        >
          URL
        </a>
      </div>
    );
  }
}

// функция подготовки данных для отображения на графике дней недели (пн, вт ...)
function analyzeWeekDay(value) {
  let datasets = [];
  let tmp = {
    всего: [0, 0, 0, 0, 0, 0, 0],
  };
  value
    .sort((a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0))
    .forEach((e) => {
      let all = 0;
      e.data.forEach((nv) => {
        if (!(nv.name in tmp)) {
          tmp[nv.name] = [0, 0, 0, 0, 0, 0, 0];
        }
        all += nv.val;
        tmp[nv.name][e.day] = nv.val;
      });
      tmp["всего"][e.day] = all;
    });
  // сдвиг воскресенья на 7 день
  for (let code in tmp) {
    tmp[code].push(tmp[code].shift());
  }
  for (let code in tmp) {
    datasets.push({
      label: code,
      backgroundColor: getRandomColor(),
      showLine: true,
      fill: false,
      data: tmp[code],
    });
  }
  return {
    datasets,
    labels: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
  };
}

// фукнция для подготовки данных для анализа по дням месяца
function analyzeMonth(value) {
  let datasets = [];
  let labels = [];
  for (let i = 0; i < 31; i++) {
    labels.push("");
  }
  let tmp = {
    всего: [],
  };
  for (let i = 0; i < 31; i++) {
    tmp["всего"].push(0);
  }
  value
    .sort((a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0))
    .forEach((e) => {
      labels[e.day - 1] = e.day;
      let all = 0;
      e.data.forEach((nv) => {
        if (!(nv.name in tmp)) {
          tmp[nv.name] = [];
          for (let i = 0; i < 31; i++) {
            tmp[nv.name].push(0);
          }
        }
        all += nv.val;
        tmp[nv.name][e.day - 1] = nv.val;
      });
      tmp["всего"][e.day - 1] = all;
    });
  for (let code in tmp) {
    datasets.push({
      label: code,
      borderColor: getRandomColor(),
      showLine: true,
      fill: false,
      data: tmp[code],
    });
  }
  return { datasets, labels };
}

// функция для подготовки данных для анализа по номерам недели
function analyzeWeek(value) {
  let datasets = [];
  let labels = [];
  for (let i = 0; i < 53; i++) {
    labels.push("");
  }
  let tmp = {
    всего: [],
  };
  for (let i = 0; i < 53; i++) {
    tmp["всего"].push(0);
  }
  value
    .sort((a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0))
    .forEach((e) => {
      labels[e.day - 1] = e.day;
      let all = 0;
      e.data.forEach((nv) => {
        if (!(nv.name in tmp)) {
          tmp[nv.name] = [];
          for (let i = 0; i < 53; i++) {
            tmp[nv.name].push(0);
          }
        }
        all += nv.val;
        tmp[nv.name][e.day - 1] = nv.val;
      });
      tmp["всего"][e.day - 1] = all;
    });
  for (let code in tmp) {
    datasets.push({
      label: code,
      borderColor: getRandomColor(),
      showLine: true,
      fill: false,
      data: tmp[code],
    });
  }
  return { datasets, labels };
}
