import { useContext, useState } from "react";
import { AccountContext, NotificationContext, ZeroID } from "base/context";
import { Load } from "base/load";
import { useHistory, useLocation } from "react-router-dom";

// страница подачи обращения с общественной организации
export function CitizenServePublicOrgPage() {
  const account = useContext(AccountContext);
  const param = new URLSearchParams(useLocation().search);
  const [data, setData] = useState({
    fullname: param.get("fullname") || account.fullname,
    phone: param.get("phone") || account.phone,
    object_address: param.get("object_address") || "",
    object_name: param.get("object_name") || "",
    reason: param.get("reason") || "",
    object_id: param.get("object_id") || ZeroID,
    lat: parseFloat(param.get("lat")) || 0,
    lon: parseFloat(param.get("lon")) || 0,
  });
  const context = useContext(NotificationContext);
  const history = useHistory();
  const save = () => {
    if (
      !confirm ||
      !data.fullname ||
      !data.object_address ||
      !data.object_name ||
      !data.phone ||
      !data.reason
    ) {
      alert("Не заполнены обязательные поля!");
      return;
    }
    new Load(
      context,
      "Подача заявки",
      "/api/citizen/new-map-appeal?pub-org=true",
      data
    )
      .SetOk("успешно", null, (id) => "Код вашей заявки: " + id)
      .SetErr()
      .Load()
      .then(() => {
        history.push("/citizen/personal");
      });
  };
  const update = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };
  const [confirm, setConfirm] = useState(false);
  const red = <span style={{ color: "red" }}>*</span>;
  return (
    <div className="centred">
      <h1>Подача обращения</h1>
      <hr />
      <table className="inline-block">
        <tbody>
          <tr>
            <td>
              <input
                placeholder="ФИО"
                className="stdin"
                value={data.fullname}
                onChange={(e) => update("fullname", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Телефон"
                className="stdin"
                value={data.phone}
                onChange={(e) => update("phone", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Адрес объекта"
                className="stdin"
                value={data.object_address}
                onChange={(e) => update("object_address", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Наименование объекта"
                className="stdin"
                value={data.object_name}
                onChange={(e) => update("object_name", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <textarea
                placeholder="Причина обращения"
                className="stdin"
                value={data.reason}
                onChange={(e) => update("reason", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <label>
                <input
                  type="checkbox"
                  checked={confirm}
                  onChange={(e) => setConfirm(e.target.checked)}
                />
                Согласие на обработку персональных данных
              </label>
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td colSpan={2}>{red} - поля обязательные для заполнения</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <button className="text-green" onClick={save}>
                Отправить
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
