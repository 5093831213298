import React, { useContext, useState } from "react";
import { DataContext, ZeroID } from "base/context";
import { ChoiseInnerDataPicker, ElementPretty, Spoiler } from "base/service";
import { SelectVisit } from "./SelectVisit";

// вкладка радактирования этапов в редакторе свода
export class Stages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
    };
    this.deleteStage = this.deleteStage.bind(this);
    this.updateStage = this.updateStage.bind(this);
  }
  updateStage(ind, stage) {
    const stages = this.props.value.map((e, i) => (i !== ind ? e : stage));
    this.props.onChange(stages);
  }
  deleteStage(ind) {
    const stages = this.props.value.filter((_, i) => i !== ind);
    this.props
      .onChange(stages)
      .then(() => this.setState({ stage: stages.length - 1 }));
  }
  addStage() {
    const stages = [
      ...this.props.value,
      {
        created: new Date().toJSON(),
        phase_id: ZeroID,
        phase_public_id: ZeroID,
        violation: false,
        visit_ids: [],
        notification: false,
        notification_time: new Date().toJSON(),
      },
    ];
    this.props
      .onChange(stages)
      .then(() => this.setState({ stage: stages.length - 1 }));
  }
  swap(from, to) {
    if (from === to) {
      return;
    }
    const items = this.props.value;
    const el = items[from];
    let tmp = items.filter((_, i) => i !== from);
    tmp.splice(to, 0, el);
    this.props.onChange(tmp);
    this.setState({ stage: to });
  }
  render() {
    const count = (this.props.value || []).length;
    return (
      <div style={{ marginTop: "10px" }}>
        <EditMenu
          count={count}
          onChange={(e) => this.setState({ stage: e })}
          add={() => this.addStage()}
          value={this.state.stage}
          swap={(from, to) => this.swap(from, to)}
          names={this.props.value.map((e) => e.phase_id)}
        />
        <ShowStage
          ind={this.state.stage}
          stages={this.props.value}
          deleteStage={this.deleteStage}
          updateStage={this.updateStage}
        />
      </div>
    );
  }
}

// функция рендера этапа (отображение выбраного этапа)
function ShowStage(props) {
  const ind = props.ind;
  if (ind < 0 || ind >= props.stages.length) {
    return null;
  }
  const stage = props.stages[ind];
  return (
    <table key={stage.created + ind} className="view">
      <tbody>
        <tr>
          <td
            colSpan="2"
            className="last"
            style={{ textAlign: "center", padding: "10px" }}
          >
            <b>Этап {ind + 1}</b>
            <button
              onClick={() => props.deleteStage(ind)}
              className="text-red"
              style={{ marginLeft: "10px" }}
            >
              удалить
            </button>
          </td>
        </tr>
        <Stage value={stage} onChange={(e) => props.updateStage(ind, e)} />
      </tbody>
    </table>
  );
}

// компонент для рендера этапа
class Stage extends React.Component {
  calcNotification(stage) {
    let status = this.context.phases.filter((e) => e.id === stage.phase_id);
    if (status.length !== 1) {
      return stage;
    }
    status = status[0];
    let st = new Date(stage.created);
    st.setDate(st.getDate() + status.period);
    stage.notification_time = st.toJSON();
    stage.notification =
      (stage.violation === true || status.notification === true) &&
      status.period > 0;
    return stage;
  }
  update(fieldName, value) {
    let tmp = { ...this.props.value };
    tmp[fieldName] = value;
    if (fieldName === "phase_id" || fieldName === "violation") {
      tmp = this.calcNotification(tmp);
    }
    this.props.onChange(tmp);
  }
  render() {
    const data = this.props.value;
    return (
      <>
        <tr>
          <td>Этап проверки</td>
          <td>
            <ChoiseInnerDataPicker
              className="stdin"
              onChange={(e) => this.update("phase_id", e)}
              value={data.phase_id}
              type="phase"
            />
          </td>
        </tr>
        <tr>
          <td>Этап проверки для гражданина</td>
          <td>
            <ChoiseInnerDataPicker
              className="stdin"
              onChange={(e) => this.update("phase_public_id", e)}
              value={data.phase_public_id}
              type="phase-public"
            />
          </td>
        </tr>
        <tr>
          <td>Время занесения в базу (автоматическое)</td>
          <td>
            <ElementPretty value={data.created} type="date" />
          </td>
        </tr>
        <tr>
          <td>Зафиксированы нарущения</td>
          <td>
            <input
              type="checkbox"
              checked={data.violation}
              onChange={(e) => this.update("violation", e.target.checked)}
              className="stdin"
            />
          </td>
        </tr>
        <tr>
          <td>Результат/принятые меры</td>
          <td>
            <textarea
              className="stdin"
              value={data.result}
              onChange={(e) => this.update("result", e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Наличие зафиксированных превышений</td>
          <td>
            <textarea
              className="stdin"
              value={data.excesses}
              onChange={(e) => this.update("excesses", e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Направление информации по компетенции</td>
          <td>
            <textarea
              className="stdin"
              value={data.info_redirect}
              onChange={(e) => this.update("info_redirect", e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Заметка</td>
          <td>
            <textarea
              className="stdin"
              value={data.note}
              onChange={(e) => this.update("note", e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>Включить уведомление</td>
          <td>
            <input
              type="checkbox"
              checked={data.notification}
              onChange={(e) => this.update("notification", e.target.checked)}
              className="stdin"
              disabled={true}
            />
          </td>
        </tr>
        <tr>
          <td className="last">
            Дата когда необходимо уведомить инспектора о неободимости смены
            статуса
          </td>
          <td className="last">
            {data.notification ? (
              <ElementPretty value={data.notification_time} type="date" />
            ) : null}
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <b>Связанные с этапом выезды</b>(
            {data.visit_ids ? data.visit_ids.length : 0})
            <Spoiler>
              <SelectVisit
                onChange={(e) => this.update("visit_ids", e)}
                value={data.visit_ids}
              />
            </Spoiler>
          </td>
        </tr>
      </>
    );
  }
}
Stage.contextType = DataContext;

// функция для рендера вкладок выбора этапа
function EditMenu(props) {
  const dataInfo = useContext(DataContext);
  let phaseMap = {};
  dataInfo.phasesToFilter.forEach((phase) => {
    phaseMap[phase.k] = phase.v;
  });
  let menu = [];
  let names = [];
  for (let i = 0; i < props.count; i++) {
    menu.push(i);
    const stage = "Этап " + (i + 1);
    if (props.names && props.names.length > i) {
      names.push(phaseMap[props.names[i]] || stage);
    } else {
      names.push(stage);
    }
  }
  // const [isDrag, setIsDrag] = useState(false);
  const [dragFrom, setDragFrom] = useState(-1);
  const [dragTo, setDragTo] = useState(-2);
  const dragEnd = () => {
    props.swap(dragFrom, dragTo);
  };
  return (
    <div className="stage-edit-menu">
      {menu.map((e) => (
        <button
          type={props.value === e ? "selected" : "menu-button"}
          onClick={() => props.onChange(e)}
          key={e}
          title={names[e]}
          draggable={true}
          onDragStart={() => setDragFrom(e)}
          onDragEnter={() => setDragTo(e)}
          onDragEnd={() => dragEnd()}
        >
          {names[e]}
        </button>
      ))}
      <button
        type="add"
        onClick={() => props.add()}
        title="Добавить новый этап"
      >
        {menu.length < 1 ? "Добавить новый этап +" : "+"}
      </button>
    </div>
  );
}
