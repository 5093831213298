import { Link, useLocation } from "react-router-dom";

// страница 404
export function NotFoundPage() {
  return (
    <div className="not-found">
      <h1>Страница не найдена</h1>
      {/* <a
      @click="$router.go(-1)"
      style="cursor: pointer; text-decoration: underline"
      >назад</a
    > */}
      <br />
      <Link to="/">на главную</Link>
    </div>
  );
}

// страница с ошибкой
export function ErrorPage() {
  const param = new URLSearchParams(useLocation().search);
  const text = param.get("text");
  const uri = param.get("uri");
  return (
    <div className="centred">
      <h1 className="text-red">Произошла ошибка:</h1>
      <h3 className="text-red">{text}</h3>
      {uri ? <a href={uri}>Вернутся</a> : null}
      <br />
      <br />
      <a href="http://krasecology.ru">«Вернуться на krasecology.ru»</a>
    </div>
  );
}

// страница с успехом
export function SuccessPage() {
  const param = new URLSearchParams(useLocation().search);
  const text = param.get("text");
  const uri = param.get("uri");
  return (
    <div className="centred">
      <h1 className="text-green">Успешно:</h1>
      <h3 className="text-green">{text}</h3>
      {uri ? <a href={uri}>Продолжить</a> : null}
      <br />
      <br />
      <a href="http://krasecology.ru">«Вернуться на krasecology.ru»</a>
      <br />
      Не забудте авторизироваться на сайте.
    </div>
  );
}
