import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";
import { Message } from "base/message";

// страница админских действий
export class ActionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
    };
    this.AFU = this.AFU.bind(this);
  }
  Test() {
    new Message("", "info").Apply(this.context);
    new Message("", "ok").SetSuccessType(true).Apply(this.context);
    new Message("", "loooooooooooooooooooooooooooooooooooooooong info").Apply(
      this.context
    );
    new Message("", "err").SetSuccessType(false).Apply(this.context);
    new Message("", "load").SetLoadType().Apply(this.context);
  }
  AFU() {
    new Load(this.context, "AFU", "/api/admin/afu")
      .SetOk()
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ result: JSON.stringify(data, undefined, 4) });
      });
  }
  GetMapUpdateInfo() {
    new Load(this.context, "map info", "/api/admin/map-update-info")
      .SetOk()
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ result: JSON.stringify(data, undefined, 4) });
      });
  }
  UpdateMap() {
    new Load(this.context, "map update", "/api/admin/update-map")
      .SetOk()
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ result: JSON.stringify(data, undefined, 4) });
      });
  }
  render() {
    return (
      <div>
        <h1>Действия</h1>
        <button onClick={() => this.AFU()}>
          Пересчитать данных ВСЕХ обращений (кэш, родитель)
        </button>
        <br />
        <button onClick={() => this.UpdateMap()}>
          Принудительно обновить карту ОНВОС
        </button>
        <br />
        <button onClick={() => this.GetMapUpdateInfo()}>
          Показать информацию об обновлении карты
        </button>
        <br />
        <button onClick={() => this.Test()}>Тест сообщений</button>
        <hr />
        <pre
          style={{
            border: "1px dotted #aaa",
          }}
        >
          {this.state.result}
        </pre>
      </div>
    );
  }
}
ActionPage.contextType = NotificationContext;
