import React from "react";
import { useParams } from "react-router-dom";
import { DataContext, NotificationContext, ZeroID } from "base/context";
import { Load } from "base/load";
import { FakeButton, MultiChoisePicker } from "base/service";
import { MainInfo } from "./MainInfo";
import { SelectObject } from "./SelectObject";
import { SelectParent } from "./SelectParent";
import { Stages } from "./Stages";
import { ViewChilds } from "./ViewChilds";
import { ViewParent } from "./ViewParent";

// страница редактора свода
export function InspectorAppealEditPage() {
  const { id } = useParams();
  return <InspectorAppealEditPageRaw id={id} />;
}

// компонент редактора свода
export class InspectorAppealEditPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      success: false,
      object: {},
      parent: {},
      childs: [],
      menu: "info",
    };
    this.save = this.save.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.updateObject = this.updateObject.bind(this);
    this.updateObject = this.updateObject.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.get(this.props.id);
    }
  }
  componentDidMount() {
    this.get(this.props.id);
  }
  get(id) {
    new Load(
      this.context,
      "Получение данных об обращении",
      "/api/inspector/get",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        if (data.inspector_ids == null) {
          data.inspector_ids = [];
        }
        if (data.stages == null) {
          data.stages = [];
        }
        this.setState({ data: data, success: true, menu: "info" });
        this.getParent(data.parent_id);
        this.getObject(data.object_id);
        this.getChilds(data.id);
      });
  }
  getParent(id) {
    if (id === ZeroID) {
      this.setState({ parent: {} });
      return;
    }
    new Load(
      this.context,
      "Получение данных об обращении",
      "/api/inspector/get-pretty",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ parent: data });
      });
  }
  getObject(id) {
    if (id === ZeroID) {
      this.setState({ object: {} });
      return;
    }
    new Load(
      this.context,
      "Получение объекта",
      "/api/object/get-pretty",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ object: data });
      });
  }
  getChilds(id) {
    if (id === ZeroID) {
      this.setState({ childs: [] });
      return;
    }
    new Load(
      this.context,
      "Получение данных об дочерних обращениях",
      "/api/inspector/get-childs",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ childs: data });
      });
  }
  save() {
    const id = this.state.data.id;
    new Load(
      this.context,
      "Сохранение обращения",
      "/api/inspector/update",
      this.state.data
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.get(id));
  }
  update(fieldName, value) {
    return new Promise((resolve) => {
      let tmp = { ...this.state.data };
      tmp[fieldName] = value;
      this.setState({ data: tmp }, resolve);
    });
  }
  updateParent(id) {
    this.update("parent_id", id);
    this.getParent(id);
  }
  updateObject(id) {
    this.update("object_id", id);
    this.getObject(id);
  }
  render() {
    const data = this.state.data;
    const header = "Обращение №" + this.state.data.number;
    const hasParent = data.parent_id && data.parent_id !== ZeroID;
    const objectInfo =
      (this.state.object.name ? this.state.object.name : "") +
      (this.state.object.object_name
        ? " (" + this.state.object.object_name + ")"
        : "") +
      (this.state.object.address ? " " + this.state.object.address : "");
    const stageStyle = { fontSize: "larger", fontStyle: "italic" };
    let page = null;
    switch (this.state.menu) {
      case "info":
        page = (
          <>
            <b style={stageStyle}>Основная информация</b>
            <MainInfo value={data} />
          </>
        );
        break;
      case "parent":
        page = (
          <>
            <b style={stageStyle}>Выбор родительской заявки</b>
            <SelectParent
              onChange={this.updateParent}
              id={data.id}
              value={data.parent_id}
            />
          </>
        );
        break;
      case "childs":
        page = (
          <>
            <b style={stageStyle}>Список дочерних заявок</b>
            <ViewChilds value={this.state.childs} />
          </>
        );
        break;
      case "inspector":
        page = (
          <>
            <b style={stageStyle}>Ответственные инспектора</b>
            <br />
            {data.inspector_ids ? (
              <DataContext.Consumer>
                {(innerData) => (
                  <MultiChoisePicker
                    value={data.inspector_ids}
                    info={innerData.inspectorsToFilter}
                    onChange={(e) => this.update("inspector_ids", e)}
                  />
                )}
              </DataContext.Consumer>
            ) : null}
          </>
        );
        break;
      case "object":
        page = (
          <>
            <b style={stageStyle}>Объект загрязнения: </b>
            {objectInfo}
            {objectInfo ? <br /> : null}
            <SelectObject onChange={this.updateObject} value={data.object_id} />
          </>
        );
        break;
      case "stages":
        page = (
          <>
            <b style={stageStyle}>Этапы</b>
            {data.stages ? (
              <Stages
                value={data.stages}
                onChange={(e) => this.update("stages", e)}
              />
            ) : null}
          </>
        );
        break;
      case "parent_info":
        page = (
          <>
            <b style={stageStyle}>Данные родительской заявки</b>
            <ViewParent value={this.state.parent} />
          </>
        );
        break;
      default:
        break;
    }
    return (
      <div>
        <h1 className="centred">{header}</h1>
        <EditMenu
          value={this.state.menu}
          onChange={(e) => this.setState({ menu: e })}
          hasParent={hasParent}
        />
        <div style={{ marginTop: "10px" }}>{page}</div>
        <div style={{ marginTop: "10px" }}>
          <button className="text-green" onClick={this.save}>
            Сохранить
          </button>
          <FakeButton url={"/inspector/view"}>
            <span className="text-red">Отмена</span>
          </FakeButton>
        </div>
      </div>
    );
  }
}
InspectorAppealEditPageRaw.contextType = NotificationContext;

// компонент реднера и выбора вкладок для редактора
function EditMenu(props) {
  const menu = [
    { name: "Основная информация", code: "info" },
    { name: "Выбор родительской заявки", code: "parent" },
  ];
  if (props.hasParent) {
    menu.push({ name: "Данные родительской заявки", code: "parent_info" });
  } else {
    menu.push(
      { name: "Список дочерних заявок", code: "childs" },
      { name: "Ответственные инспектора", code: "inspector" },
      { name: "Объект загрязнения", code: "object" },
      { name: "Этапы", code: "stages" }
    );
  }
  return (
    <div className="inspector-edit-menu">
      {menu.map((e) => (
        <button
          type={props.value === e.code ? "selected" : "none"}
          onClick={() => props.onChange(e.code)}
          key={e.code}
        >
          {e.name}
        </button>
      ))}
    </div>
  );
}
