import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";

// страница редактирования публицных фаз (для карты ОНВОС)
export class PhasesPublicPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  get() {
    new Load(this.context, "Получение списка этапов", "/api/info/public-phases")
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ data: data });
      });
  }
  create() {
    this.setState((state) => ({
      data: [...state.data, { id: "new", color: "#000000" }],
    }));
  }
  render() {
    return (
      <div>
        <h1>Редактирование публичных этапов работы с заявкой</h1>
        <button onClick={this.create}>новый</button>
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Название статуса</td>
              <td>Цвет на карте</td>
              <td>Отображать на карте</td>
              <td>Заметка</td>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((e) => (
              <PhasesPublicRow phase={e} key={e.id} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
PhasesPublicPage.contextType = NotificationContext;

// компонент рендера публицной фазы
class PhasesPublicRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.phase.id,
      name: props.phase.name,
      color: props.phase.color,
      note: props.phase.note,
      show_on_map: props.phase.show_on_map,
      update: false,
    };
    this.update = this.update.bind(this);
  }
  update() {
    let data = { ...this.state };
    data.id = this.state.id === "new" ? undefined : this.state.id;
    new Load(
      this.context,
      "Обновление данных этапа",
      "/api/admin/" +
        (this.state.id !== "new" ? "edit" : "create") +
        "-public-phase",
      data
    )
      .SetErr()
      .SetOk()
      .Load()
      .then(() => {
        this.props.get();
        this.setState({ update: false });
      });
  }
  render() {
    return (
      <tr>
        <td>{this.state.id}</td>
        <td>
          <input
            value={this.state.name}
            onChange={(e) =>
              this.setState({ name: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            type="color"
            value={this.state.color}
            onChange={(e) =>
              this.setState({ color: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={this.state.show_on_map}
            onChange={(e) =>
              this.setState({ show_on_map: e.target.checked, update: true })
            }
          />
        </td>
        <td>
          <input
            value={this.state.note}
            onChange={(e) =>
              this.setState({ note: e.target.value, update: true })
            }
          />
        </td>
        <td>
          {this.state.update ? (
            <button className="text-green" onClick={this.update}>
              Сохранить
            </button>
          ) : null}
        </td>
      </tr>
    );
  }
}
PhasesPublicRow.contextType = NotificationContext;
