import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";

// страница редактирования фаз (для карты ОНВОС)
export class PhasesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.get = this.get.bind(this);
    this.create = this.create.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  create() {
    this.setState((state) => ({
      data: [...state.data, { id: "new" }],
    }));
  }
  get() {
    new Load(this.context, "Получение списка этапов", "/api/info/phases")
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ data: data });
      });
  }
  render() {
    return (
      <div>
        <h1>Редактирование этапов работы с заявкой</h1>
        <button onClick={this.create}>новый</button>
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>название статуса</td>
              <td>обязательное уведомление</td>
              <td>время уведомления (в сутках)</td>
              <td>заметка</td>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((e) => (
              <PhasesRow phase={e} key={e.id} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
PhasesPage.contextType = NotificationContext;

// компонент для рендера фазы
class PhasesRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.phase.id,
      name: props.phase.name,
      notification: props.phase.notification,
      period: props.phase.period,
      note: props.phase.note,
      update: false,
    };
    this.update = this.update.bind(this);
  }
  update() {
    let data = { ...this.state };
    data.id = this.state.id === "new" ? undefined : this.state.id;
    new Load(
      this.context,
      "Обновление данных этапа",
      "/api/admin/" + (this.state.id !== "new" ? "edit" : "create") + "-phase",
      data
    )
      .SetErr()
      .SetOk()
      .Load()
      .then(() => {
        this.props.get();
        this.setState({ update: false });
      });
  }
  render() {
    return (
      <tr>
        <td>{this.state.id}</td>
        <td>
          <input
            value={this.state.name}
            onChange={(e) =>
              this.setState({ name: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            type="checkbox"
            checked={this.state.notification}
            onChange={(e) =>
              this.setState({ notification: e.target.checked, update: true })
            }
          />
        </td>
        <td>
          <input
            type="number"
            value={this.state.period}
            onChange={(e) =>
              this.setState({ period: parseInt(e.target.value), update: true })
            }
          />
        </td>
        <td>
          <input
            value={this.state.note}
            onChange={(e) =>
              this.setState({ note: e.target.value, update: true })
            }
          />
        </td>
        <td>
          {this.state.update ? (
            <button className="text-green" onClick={this.update}>
              Сохранить
            </button>
          ) : null}
        </td>
      </tr>
    );
  }
}
PhasesRow.contextType = NotificationContext;
