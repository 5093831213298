import React from "react";
import { DataContext, NotificationContext } from "base/context";
import {
  BoolFilter,
  DateFilter,
  MultiChoiseFilter,
  Paginator,
  TextFilter,
} from "base/filter";
import { Load } from "base/load";
import { ElementPretty, FakeButton, ShowIfPerm } from "base/service";

// страница просмотра выездов
export class VisitViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        inspector: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getExcel = this.getExcel.bind(this);
    this.timeID = null;
  }
  componentDidMount() {
    this.get();
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка выездов",
      "/api/visit/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  getExcel() {
    new Load(
      this.context,
      "Получение списка выездов EXCEL",
      "/api/visit/filter-excel",
      this.state.filter
    )
      .SetErr()
      .Load(true)
      .then((data) => {
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  render() {
    return (
      <div>
        <h1>Список выездов</h1>
        <hr />
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        >
          <ShowIfPerm value="citizen-journal:visit:c">
            <FakeButton url={"/visit/edit/new"} style={{ marginLeft: "20px" }}>
              <b className="text-green">Новый</b>
            </FakeButton>
          </ShowIfPerm>
          <button
            onClick={this.getExcel}
            title="скачать выезды"
            style={{ marginLeft: "10px" }}
          >
            <img
              src="/img/excel.png"
              style={{ verticalAlign: "middle", height: "15px" }}
              alt="скачать"
            />
            Скачать Excel
          </button>
        </Paginator>
        <hr />
        <table className="view" style={{ width: "100%" }}>
          <thead className="double visit">
            <VisitViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody>
            {this.state.data.map((e, ind) => (
              <VisitViewRow key={ind} row={e} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
VisitViewPage.contextType = NotificationContext;

// шапка таблицы выездов
export class VisitViewHead extends React.Component {
  render() {
    return (
      <>
        <tr>
          <td>Действия</td>
          <td>Время занесения в базу</td>
          <td>Инспектор</td>
          <td>Был выезд</td>
          <td>Время прибытия</td>
          <td>Адрес</td>
          <td>Источники негативного воздействия</td>
          <td>Отбор проб</td>
          <td>Результат</td>
          <td>Заметка</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <DateFilter
              value={this.props.filter.registration}
              onChange={(e) => this.props.updateFilter("registration", e)}
            />
          </td>
          <td>
            <MultiChoiseFilter
              value={this.props.filter.inspector}
              info={this.context.inspectorsToFilter}
              onChange={(e) => this.props.updateFilter("inspector", e)}
            />
          </td>
          <td>
            <BoolFilter
              value={this.props.filter.exist}
              onChange={(e) => this.props.updateFilter("exist", e)}
            />
          </td>
          <td>
            <DateFilter
              value={this.props.filter.arrival}
              onChange={(e) => this.props.updateFilter("arrival", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.address}
              onChange={(e) => this.props.updateFilter("address", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.sources}
              onChange={(e) => this.props.updateFilter("sources", e)}
            />
          </td>
          <td>
            <BoolFilter
              value={this.props.filter.sampling}
              onChange={(e) => this.props.updateFilter("sampling", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.result}
              onChange={(e) => this.props.updateFilter("result", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.note}
              onChange={(e) => this.props.updateFilter("note", e)}
            />
          </td>
        </tr>
      </>
    );
  }
}
VisitViewHead.contextType = DataContext;

// строка таблицы выездов
export class VisitViewRow extends React.Component {
  constructor(props) {
    super(props);
    this.del = this.del.bind(this);
    this.select = this.select.bind(this);
  }
  del() {
    if (window.confirm("Это действие удалит выезд, продолжить?") === false) {
      return;
    }
    new Load(
      this.context,
      "Удаление выезда",
      "/api/visit/delete",
      JSON.stringify(this.props.row.id)
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.props.get());
  }
  select() {
    if (this.props.onChange === undefined) {
      return;
    }
    const id = this.props.row.id;
    const value = this.props.value;
    if (value.includes(id)) {
      this.props.onChange(value.filter((e) => e !== id));
    } else {
      this.props.onChange([...value, id]);
    }
  }
  calcType() {
    const id = this.props.row.id;
    const smode = this.props.smode ? true : false;
    if (smode && this.props.value.includes(id)) {
      return "selected";
    }
    return "none";
  }
  render() {
    const row = this.props.row;
    const url = "/visit/edit/" + row.id;
    const type = this.calcType();
    return (
      <tr type={type}>
        {this.props.smode ? (
          <td>
            <button onClick={this.select} title="выбрать">
              {this.props.value.includes(row.id) ? (
                <img src="/img/decline.png" className="menu" alt="отклонить" />
              ) : (
                <img src="/img/accept.png" className="menu" alt="принять" />
              )}
            </button>
          </td>
        ) : (
          <td>
            <ShowIfPerm value="citizen-journal:visit:u">
              <FakeButton url={url}>
                <img
                  src="/img/edit.png"
                  className="menu"
                  alt="редактор"
                  title="открыть в редакторе"
                />
              </FakeButton>
            </ShowIfPerm>
            <ShowIfPerm value="citizen-journal:visit:d">
              <button onClick={this.del} title="удалить">
                <img src="/img/delete.png" className="menu" alt="удаление" />
              </button>
            </ShowIfPerm>
          </td>
        )}
        <td>
          <ElementPretty value={row.registration} type="date" />
        </td>
        <td className="centred">{row.inspector}</td>
        <td className="centred">
          <ElementPretty value={row.exist} type="bool" />
        </td>
        <td>
          {row.exist ? <ElementPretty value={row.arrival} type="date" /> : null}
        </td>
        <td className="centred">{row.address}</td>
        <td className="centred">{row.sources}</td>
        <td className="centred">
          <ElementPretty value={row.sampling} type="bool" />
        </td>
        <td className="centred">{row.result}</td>
        <td className="centred">{row.note}</td>
      </tr>
    );
  }
}
VisitViewRow.contextType = NotificationContext;
