import React from "react";
import { ElementPretty } from "base/service";

// компонент рендера основной информации о заявке
export function MainInfo(props) {
  const appeal = props.value;
  return (
    <table>
      <tbody>
        <tr>
          <td className="info">ID</td>
          <td className="data">{appeal.id}</td>
        </tr>
        <tr>
          <td className="info">Номер заявки</td>
          <td className="data">{appeal.number}</td>
        </tr>
        <tr>
          <td className="info">Зарегестрирован</td>
          <td className="data">
            <ElementPretty value={appeal.registration} type="date" />
          </td>
        </tr>
        {appeal.data !== undefined ? (
          <>
            <tr>
              <td className="info">Адрес нарушения</td>
              <td className="data">{appeal.data.address}</td>
            </tr>
            <tr>
              <td className="info">Объект нарушения</td>
              <td className="data">{appeal.data.object_name}</td>
            </tr>
            <tr>
              <td className="info">Адрес проживания</td>
              <td className="data">{appeal.data.residence}</td>
            </tr>
            <tr>
              <td className="info">ФИО заявителя</td>
              <td className="data">{appeal.data.fullname}</td>
            </tr>
            <tr>
              <td className="info">Район</td>
              <td className="data">{appeal.data.district}</td>
            </tr>
            <tr>
              <td className="info">Микрорайон</td>
              <td className="data">{appeal.data.microdistrict}</td>
            </tr>
            <tr>
              <td className="info">Телефон заявителя</td>
              <td className="data">{appeal.data.phone}</td>
            </tr>
            <tr>
              <td className="info">Причина звонка</td>
              <td className="data">{appeal.data.reason}</td>
            </tr>
          </>
        ) : null}
      </tbody>
    </table>
  );
}
