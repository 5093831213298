import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App.js";

// компонент рендера внутренней ошибки
class Er extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "", errorInfo: "" };
  }
  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
    this.setState({
      hasError: true,
      errorInfo: info.componentStack,
      error: error.toString(),
    });
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="centred error-page">
          <h1 className="text-red">Произошла ошибка</h1>
          <p>{this.state.error}</p>
          <br />
          <p style={{ marginTop: "30px" }}>{this.state.errorInfo}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

// добавление рендера основного элемента
ReactDOM.render(
  <React.StrictMode>
    <Er>
      <App />
    </Er>
  </React.StrictMode>,
  document.getElementById("root")
);
