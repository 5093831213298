import React from "react";
import { Link } from "react-router-dom";
import { AccountContext, DataContext, ZeroID } from "./context";

// конвертирует время из JSON в локальное
export function toLocalTime(ts) {
  return new Date(ts).toLocaleString();
}

// преобразователь данных в человеко-читаемый вид
export class ElementPretty extends React.Component {
  render() {
    let val = this.props.value;
    if (val === null || val === undefined) {
      return <>{""}</>;
    }
    const keyFromFilter = (list, key, def = null) => {
      for (let i = 0; i < list.length; i++) {
        if (list[i].k === key) {
          return list[i].v;
        }
      }
      return def;
    };
    try {
      switch (this.props.type) {
        case "date":
          val = toLocalTime(val);
          break;
        case "bool":
          val = val ? "да" : "нет";
          break;
        case "registrar":
          val = keyFromFilter(this.context.registrarType, val, "неизвестно");
          break;
        case "accepted":
          val = keyFromFilter(this.context.accepted, val, "неизвестно");
          break;
        case "join":
          val = val.join(", ");
          break;
        default:
          break;
      }
    } catch {
      val = "ошибка конвертации";
    }
    return <>{val}</>;
  }
}
ElementPretty.contextType = DataContext;

// кнопка спойлера
export function SpoilerButton(props) {
  const hideName = props.hideName === undefined ? "скрыть" : props.hideName;
  const showName = props.showName === undefined ? "показать" : props.showName;
  const value = props.value;
  return (
    <div onClick={() => props.onChange(!value)} className="spoiler-button">
      [{value ? hideName : showName}]
    </div>
  );
}

// спойлер
export class Spoiler extends React.Component {
  constructor(props) {
    super(props);
    // установка начального значения показа данных
    this.state = {
      value: props.show === true, // чтобы отсеять undefined
    };
  }
  render() {
    return (
      <div className="inline">
        <SpoilerButton
          value={this.state.value}
          onChange={(e) => this.setState({ value: e })}
          hideName={this.props.hideName}
          showName={this.props.showName}
        />
        {this.state.value ? <>{this.props.children}</> : null}
      </div>
    );
  }
}

// компонент для ввода даты/времени
export class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.parse(props.value);
  }
  parse(value) {
    const addHeadZero = (e) => {
      if (e < 10) {
        return "0" + e;
      }
      return e.toString();
    };
    let time = new Date(value);
    let dt =
      "" +
      time.getFullYear() +
      "-" +
      addHeadZero(1 + time.getMonth()) +
      "-" +
      addHeadZero(time.getDate());
    let tm =
      "" +
      addHeadZero(time.getHours()) +
      ":" +
      addHeadZero(time.getMinutes()) +
      ":" +
      addHeadZero(time.getSeconds());
    return { date: dt, time: tm };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.date !== prevState.date ||
      this.state.time !== prevState.time
    ) {
      this.props.onChange(
        new Date(this.state.date + " " + this.state.time).toJSON()
      );
    }
    if (this.props.value !== prevProps.value) {
      this.setState(this.parse(this.props.value));
    }
  }
  render() {
    return (
      <div title={toLocalTime(this.props.value)} className="inline">
        <input
          type="date"
          value={this.state.date}
          onChange={(e) => this.setState({ date: e.target.value })}
        />
        <input
          type="time"
          value={this.state.time}
          onChange={(e) => this.setState({ time: e.target.value })}
          step="1"
          pattern="\d\d:\d\d:\d\d"
        />
      </div>
    );
  }
}
DateTimePicker.defaultProps = {
  value: new Date().toJSON(),
};

// компонент для множественного выбора
export class MultiChoisePicker extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }
  update(v) {
    const value = this.props.value;
    let tmp = [...value];
    if (value.includes(v)) {
      tmp = value.filter((e) => e !== v);
    } else {
      tmp.push(v);
    }
    this.props.onChange(tmp);
  }
  render() {
    return (
      <ul className="multi-choise-picker">
        {this.props.info.map((e) => (
          <li key={e.k} style={{ whiteSpace: "nowrap", textAlign: "left" }}>
            <label>
              <input
                type="checkbox"
                checked={this.props.value.includes(e.k)}
                onChange={() => this.update(e.k)}
              />
              {e.v}
            </label>
          </li>
        ))}
      </ul>
    );
  }
}

// компонент ссылки замаскированный под кнопку
export function FakeButton(props) {
  return (
    <Link className="fake-button" to={props.url} style={props.style}>
      {props.children}
    </Link>
  );
}

// компонент для выбора сотрудника
export class ChoiseEmployeePicker extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }
  update(e) {
    this.props.onChange(e.target.value);
  }
  render() {
    let data =
      this.props.type === "inspector"
        ? this.context.inspectors
        : this.props.type === "operator"
        ? this.context.operators
        : [];
    data = this.props.show ? data.filter((e) => !e.dont_show) : data;
    return (
      <select
        value={this.props.value}
        onChange={this.update}
        className={this.props.className}
      >
        <option defaultValue={ZeroID} hidden={true}>
          выбрать
        </option>
        {data.map((e) => (
          <option key={e.id} value={e.id} title={e.fullname}>
            {e.fullname}
          </option>
        ))}
      </select>
    );
  }
}
ChoiseEmployeePicker.contextType = DataContext;

// компонент для выбора внутренних данных
export class ChoiseInnerDataPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.update = this.update.bind(this);
  }
  update(e) {
    this.props.onChange(e.target.value);
  }
  render() {
    const info =
      this.props.type === "category"
        ? this.context.category
        : this.props.type === "oversight"
        ? this.context.oversight
        : this.props.type === "registrar"
        ? this.context.registrarType
        : this.props.type === "accepted"
        ? this.context.accepted
        : this.props.type === "phase"
        ? this.context.phasesToFilter
        : this.props.type === "phase-public"
        ? this.context.phasesPublicToFilter
        : [];
    return (
      <select
        value={this.props.value}
        onChange={this.update}
        className={this.props.className}
      >
        <option defaultValue={ZeroID} hidden={true}>
          выбрать
        </option>
        <option defaultValue={""} hidden={true}>
          выбрать
        </option>
        <option defaultValue={null} hidden={true}>
          выбрать
        </option>
        <option defaultValue={undefined} hidden={true}>
          выбрать
        </option>
        {info.map((e) => (
          <option key={e.k} value={e.k} title={e.v}>
            {e.v}
          </option>
        ))}
      </select>
    );
  }
}
ChoiseInnerDataPicker.contextType = DataContext;

// компонент для условной отрисовки при налиции прав
export class ShowIfPerm extends React.Component {
  render() {
    let hasPerm = false;
    if (this.props.any && this.props.any.length > 0) {
      hasPerm =
        this.props.any
          .map((e) => this.context.checkPerm(e))
          .filter((e) => e === true).length > 0;
    } else {
      hasPerm = this.context.checkPerm(this.props.value);
    }
    const redirect = this.props.redirect ? true : false;
    return (
      <>
        {hasPerm ? (
          this.props.children
        ) : redirect ? (
          // <Redirect to="/error?text=Доступ+запрещен!" />
          <h1 className="text-red">Доступ запрещен!</h1>
        ) : null}
      </>
    );
  }
}
ShowIfPerm.contextType = AccountContext;
