import React from "react";
import { useParams } from "react-router-dom";
import { NotificationContext, ZeroID } from "base/context";
import { Load } from "base/load";
import {
  ChoiseEmployeePicker,
  DateTimePicker,
  ElementPretty,
  FakeButton,
} from "base/service";

// страница редактора выездов
export function VisitEditPage() {
  const { id } = useParams();
  return <VisitEditPageRaw id={id} />;
}

// компонент - редактор выездов
export class VisitEditPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
    this.save = this.save.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.get(this.props.id);
    }
  }
  componentDidMount() {
    this.get(this.props.id);
  }
  get(id) {
    if (id === "new") {
      this.setState({
        data: {
          registration: new Date().toJSON(),
          exist: false,
          arrival: new Date().toJSON(),
          sampling: false,
          inspector_id: ZeroID,
        },
      });
      return;
    }
    new Load(
      this.context,
      "Получение выезда",
      "/api/visit/get",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ data: data });
      });
  }
  save() {
    const id = this.state.data.id;
    new Load(
      this.context,
      "Сохранение выезда",
      "/api/visit/" + (id ? "update" : "create"),
      this.state.data
    )
      .SetOk()
      .SetErr()
      .Load()
      .then((data) => this.get(id ? id : data));
  }
  update(fieldName, value) {
    let tmp = { ...this.state.data };
    tmp[fieldName] = value;
    this.setState({ data: tmp });
  }
  render() {
    const data = this.state.data;
    const header =
      data.id !== "new" && data.id !== undefined
        ? "Выезд #" + data.id
        : "Новый выезд";
    return (
      <div>
        <h1 className="centred">{header}</h1>
        <table>
          <tbody>
            <tr>
              <td className="info">ID</td>
              <td className="data">{data.id}</td>
            </tr>
            <tr>
              <td className="info">Зарегестрирован</td>
              <td className="data">
                <ElementPretty value={data.registration} type="date" />
              </td>
            </tr>
            <tr>
              <td className="info">Инспектор</td>
              <td className="data">
                <ChoiseEmployeePicker
                  value={data.inspector_id}
                  onChange={(e) => this.update("inspector_id", e)}
                  type="inspector"
                  show={true}
                  className="stdin"
                />
              </td>
            </tr>
            <tr>
              <td className="info">Был выезд</td>
              <td className="data">
                <input
                  className="stdin"
                  type="checkbox"
                  checked={data.exist}
                  onChange={(e) => this.update("exist", e.target.checked)}
                />
              </td>
            </tr>
            {data.exist ? (
              <tr>
                <td className="info">Время выезда</td>
                <td className="data">
                  <DateTimePicker
                    value={data.arrival}
                    onChange={(e) => this.update("arrival", e)}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="info">Адрес</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.address}
                  onChange={(e) => this.update("address", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Источники негативного воздействия</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.sources}
                  onChange={(e) => this.update("sources", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Отбор проб</td>
              <td className="data">
                <input
                  className="stdin"
                  type="checkbox"
                  checked={data.sampling}
                  onChange={(e) => this.update("sampling", e.target.checked)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Результат</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.result}
                  onChange={(e) => this.update("result", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Заметка</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.note}
                  onChange={(e) => this.update("note", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="centred">
                <button className="text-green" onClick={this.save}>
                  Сохранить
                </button>
                <FakeButton url={"/visit/view"}>
                  <span className="text-red">Отмена</span>
                </FakeButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
VisitEditPageRaw.contextType = NotificationContext;
