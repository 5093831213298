import React from "react";

// контекст для уведомлений
export const NotificationContext = React.createContext({
  addNotification: () => {}, // добавляет уведомление
  delNotification: () => {}, // удаляет уведомление
});

// контекст для авторизации
export const AuthContext = React.createContext({
  login: () => {}, // вход
  logout: () => {}, // выход
});

// контекст для аккаунта пользователя
export const AccountContext = React.createContext({
  accountInfo: {}, // информация о пользователе
  logged: false, // авторизирован
  fullname: "", // ФИО пользователя
  checkPerm: () => {
    // проверка привелегии
    return false;
  },
});

// контекст для данных системы (справочные таблицы)
export const DataContext = React.createContext({
  staff: [], // весь персонал
  tags: [], // эко теги
  phases: [], // фазы свода
  phasesPublic: [], // фазы для гражданина
  inspectors: [], // инспектора
  operators: [], // операторы
  inspectorsToFilter: [], // инспектора для фильтра
  operatorsToFilter: [], // операторы для фильтра
  tagsToFilter: [], // тэги для фильтра
  phasesToFilter: [], // фазы свода для фильтра
  phasesPublicToFilter: [], // фазы для гражданина, для фильтра
  category: [], // категории объектов негативного воздействия
  oversight: [], // категории ОНВОС
  registrarType: [], // типы подавшего заявку
  accepted: [], // типы статуса заявки
});

// пустой ID mongo
export const ZeroID = "000000000000000000000000";

// определяет мобильно приложение или нет
export const IsMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
  navigator.userAgent
);
