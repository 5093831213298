import { useContext, useState } from "react";
import { AuthContext } from "base/context.js";

// функция рендера страницы авторизации/компонента
export function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const context = useContext(AuthContext);
  const login = () => context.login(email, password);
  const handleKeypress = (e) => {
    if (e.keyCode === 13 || e.charCode === 13) {
      login();
    }
  };
  return (
    <div className="login">
      {props.requareAuth ? (
        <h1 className="text-red" style={{ fontSize: "xxx-large" }}>
          Необходима авторизация!
        </h1>
      ) : null}
      <h1 className="text-blue">Авторизация</h1>
      <input
        name="email"
        className="field"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Логин или электроная почта"
        onKeyPress={handleKeypress}
      />
      <br />
      <input
        name="password"
        className="field"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Пароль"
        onKeyPress={handleKeypress}
      />
      <br />
      <button className="login" onClick={login}>
        Войти
      </button>
      <br />
      <a href="http://account.krasecology.ru/#/registration">Регистрация</a>
      <br />
      <a href="http://account.krasecology.ru/#/password-reset">
        Забыли пароль?
      </a>
    </div>
  );
}
