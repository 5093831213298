import axios from "axios";
import { Message } from "./message.js";

// функция загрузки данных (интегрированая в интерфейс)
export class Load extends Message {
  constructor(context, header, url = null, obj = null) {
    super(header);
    this.SetLoadType(); // выставляем тип сообщения : загрузка
    this.context = context;
    // устанавливаем токен для отмены загрузки
    this.cancelToken = axios.CancelToken.source();
    this.SetClickFunction(this.cancelToken.cancel);

    this.url = url; // адрес загрузки
    this.obj = obj; // данные для загрузки
    // сообщение о успехе
    this.okMessage = new Message()
      .SetSuccessType(true)
      .SetHeader("успешно")
      .SetCloseTimeout(30);
    this.okMessageHandler = null; // функция для обработки текста сообщения о успехе
    this.okShow = false; // показывать сообщение о успеехе
    // сообщение о неудаче
    this.errMessage = new Message()
      .SetSuccessType(false)
      .SetHeader("ошибка")
      .SetCloseTimeout(60);
    this.errShow = false; // показывать сообщение о неудаче
  }
  SetReq(url, obj = null) {
    this.url = url;
    this.obj = obj;
    return this;
  }
  SetOk(message = "успешно", clk = null, handler = null) {
    this.okShow = true;
    this.okMessage.SetMessage(message).SetClickFunction(clk);
    this.okMessageHandler = handler;
    return this;
  }
  SetErr(header = "ошибка", clk = null) {
    this.errShow = true;
    this.errMessage.SetHeader(header).SetClickFunction(clk);
    return this;
  }
  Load(blob = false) {
    return new Promise((resolve, reject) => {
      if (this.url == null) {
        return;
      }
      this.Apply(this.context);
      axios
        .post(this.url, this.obj, {
          timeout: 60 * 1000,
          cancelToken: this.cancelToken.token,
          responseType: blob ? "blob" : "json",
        })
        .then((response) => {
          resolve(response.data);
          this.Close();
          if (this.okShow) {
            if (this.okMessageHandler != null) {
              this.okMessage.SetMessage(this.okMessageHandler(response.data));
            }
            this.okMessage.Apply(this.context);
          }
        })
        .catch((err) => {
          reject(err);
          this.Close();
          if (this.errShow) {
            this.errMessage.SetMessage(err.response.data);
            this.errMessage.Apply(this.context);
          }
        });
    });
  }
}
