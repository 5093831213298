import React from "react";
import { NotificationContext } from "base/context";
import { Paginator } from "base/filter";
import { Load } from "base/load";
import { VisitViewHead, VisitViewRow } from "../visit/view";

// компонент выбора выезвод для редактора свода (во вкладке этапы)
export class SelectVisit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        inspector: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.timeID = null;
  }
  componentDidMount() {
    this.get();
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка выездов",
      "/api/visit/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  render() {
    return (
      <div>
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        ></Paginator>
        <hr />
        <table className="view">
          <thead className="double sub-info">
            <VisitViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody className="sub-info">
            {this.state.data.map((e, ind) => (
              <VisitViewRow
                key={ind}
                row={e}
                get={this.get}
                smode={true}
                onChange={(e) => this.props.onChange(e)}
                value={this.props.value}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
SelectVisit.contextType = NotificationContext;
