import React from "react";
import { DataContext, NotificationContext, ZeroID } from "base/context";
import {
  BoolFilter,
  DateFilter,
  MultiChoiseFilter,
  NumberFilter,
  Paginator,
  TextFilter,
} from "base/filter";
import { Load } from "base/load";
import { ElementPretty, FakeButton, ShowIfPerm } from "base/service";

// страница свода
export class InspectorAppealViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      onlyNotify: false,
      filter: {
        need_copmlete: false,
        number: null,
        inspector_ids: [],
        registrar_type: [],
        accepted: [1],
        tags: [],
        phase_id: [],
        phase_public_id: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getExcel = this.getExcel.bind(this);
    this.timeID = null;
  }
  componentDidMount() {
    this.get();
  }
  componentDidUpdate(_, prevState) {
    if (this.state.onlyNotify !== prevState.onlyNotify) {
      this.get();
    }
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка обращений",
      "/api/inspector/filter" +
        (this.state.onlyNotify ? "?only-notify=true" : ""),
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  getExcel() {
    new Load(
      this.context,
      "Получение списка обращений EXCEL",
      "/api/inspector/filter-excel" +
        (this.state.onlyNotify ? "?only-notify=true" : ""),
      this.state.filter
    )
      .SetErr()
      .Load(true)
      .then((data) => {
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  render() {
    return (
      <div>
        <h1>СВОД</h1>
        <hr />
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        >
          <label>
            <input
              type="checkbox"
              style={{ verticalAlign: "middle", marginLeft: "10px" }}
              checked={this.state.onlyNotify}
              onChange={(e) => this.setState({ onlyNotify: e.target.checked })}
            />
            Только уведомления
          </label>
          <label>
            <input
              type="checkbox"
              style={{ verticalAlign: "middle", marginLeft: "10px" }}
              checked={this.state.filter.need_copmlete}
              onChange={(e) =>
                this.updateFilter("need_copmlete", e.target.checked)
              }
            />
            Необходимо заполнить
          </label>
          <button
            onClick={this.getExcel}
            title="скачать СВОД"
            style={{ marginLeft: "10px" }}
          >
            <img
              src="/img/excel.png"
              style={{ verticalAlign: "middle", height: "15px" }}
              alt="скачать"
            />
            Скачать Excel
          </button>
        </Paginator>
        <hr />
        <table className="view">
          <thead className="double inspector">
            <InspectorAppealViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody className="inspector">
            {this.state.data.map((e, ind) => (
              <InspectorAppealViewRow key={ind} row={e} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
InspectorAppealViewPage.contextType = NotificationContext;

// компонент для рендера шапки таблицы свода
export class InspectorAppealViewHead extends React.Component {
  render() {
    return (
      <>
        <tr>
          <td>Действия</td>
          <td>Номер заявки</td>
          <td>Инспектора</td>
          <td>Дочерняя заявка</td>
          <td>Время занесения в базу</td>
          <td>Адрес нарушения</td>
          <td>Объект нарушения</td>
          <td>Адрес заявителя</td>
          <td>ФИО заявителя</td>
          <td>Район</td>
          <td>Микрорайон</td>
          <td>Телефон заявителя</td>
          <td>Причина звонка</td>
          <td>Тип подавшего заявку</td>
          <td>
            <p className="vertical">Прошла ли заявка модерацию</p>
          </td>
          <td>Тэги</td>
          <td>
            <p className="vertical">имеет этапы</p>
          </td>
          <td>
            <p className="vertical">количество выездов</p>
          </td>
          <td>фаза</td>
          <td>фаза гражданин</td>
          <td>крайний срок</td>
          <td>
            <p className="vertical">количество дочерних заявок</p>
          </td>
          <td>Результат</td>
          <td>Нарушения</td>
          <td>Перенаправление информации по компетенции</td>
        </tr>
        {this.props.disableFilter === true ? null : (
          <tr>
            <td></td>
            <td>
              <NumberFilter
                value={this.props.filter.number}
                onChange={(e) => this.props.updateFilter("number", e)}
              />
            </td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.inspector_ids}
                info={this.context.inspectorsToFilter}
                onChange={(e) => this.props.updateFilter("inspector_ids", e)}
              />
            </td>
            <td>
              <BoolFilter
                value={this.props.filter.has_parent}
                onChange={(e) => this.props.updateFilter("has_parent", e)}
              />
            </td>
            <td>
              <DateFilter
                value={this.props.filter.registration}
                onChange={(e) => this.props.updateFilter("registration", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.address}
                onChange={(e) => this.props.updateFilter("address", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.object_name}
                onChange={(e) => this.props.updateFilter("object_name", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.residence}
                onChange={(e) => this.props.updateFilter("residence", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.fullname}
                onChange={(e) => this.props.updateFilter("fullname", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.district}
                onChange={(e) => this.props.updateFilter("district", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.microdistrict}
                onChange={(e) => this.props.updateFilter("microdistrict", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.phone}
                onChange={(e) => this.props.updateFilter("phone", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.reason}
                onChange={(e) => this.props.updateFilter("reason", e)}
              />
            </td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.registrar_type}
                info={this.context.registrarType}
                onChange={(e) => this.props.updateFilter("registrar_type", e)}
              />
            </td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.accepted}
                info={this.context.accepted}
                onChange={(e) => this.props.updateFilter("accepted", e)}
              />
            </td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.tags}
                info={this.context.tagsToFilter}
                onChange={(e) => this.props.updateFilter("tags", e)}
              />
            </td>
            <td></td>
            <td></td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.phase_id}
                info={this.context.phasesToFilter}
                onChange={(e) => this.props.updateFilter("phase_id", e)}
              />
            </td>
            <td>
              <MultiChoiseFilter
                value={this.props.filter.phase_public_id}
                info={this.context.phasesPublicToFilter}
                onChange={(e) => this.props.updateFilter("phase_public_id", e)}
              />
            </td>
            <td>
              <DateFilter
                value={this.props.filter.notification_time}
                onChange={(e) =>
                  this.props.updateFilter("notification_time", e)
                }
              />
            </td>
            <td></td>
            <td>
              <TextFilter
                value={this.props.filter.result}
                onChange={(e) => this.props.updateFilter("result", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.excesses}
                onChange={(e) => this.props.updateFilter("excesses", e)}
              />
            </td>
            <td>
              <TextFilter
                value={this.props.filter.info_redirect}
                onChange={(e) => this.props.updateFilter("info_redirect", e)}
              />
            </td>
          </tr>
        )}
      </>
    );
  }
}
InspectorAppealViewHead.contextType = DataContext;

// компонент для рендера строки таблицы свода
export class InspectorAppealViewRow extends React.Component {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
    this.getExcel = this.getExcel.bind(this);
  }
  getExcel() {
    const id = this.props.row.id;
    new Load(
      this.context,
      "Получение списка обращений EXCEL",
      "/api/inspector/get-pretty-excel",
      JSON.stringify(id)
    )
      .SetErr()
      .Load(true)
      .then((data) => {
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id + ".xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  select() {
    if (this.props.onChange === undefined) {
      return;
    }
    if (this.props.row.id === this.props.value) {
      this.props.onChange(ZeroID);
    } else {
      this.props.onChange(this.props.row.id);
    }
  }
  calcType() {
    const row = this.props.row;
    const currentID = this.props.id;
    const smode = this.props.smode ? true : false;
    if (smode) {
      if (row.id === currentID) {
        return "current";
      }
      if (row.id === this.props.value) {
        return "selected";
      }
    } else {
      if (row.has_parent) {
        return "child";
      }
      if (!row.cache || !row.cache.inspectors) {
        return "dont-work";
      }
    }
    return "none";
  }
  render() {
    const url = "/inspector/edit/" + this.props.row.id;
    const currentID = this.props.id;
    const row = this.props.row;
    const hasCache = row.cache ? true : false;
    const hasLastStage = row.cache && row.cache.last_stage;
    const hasAlertLastStage =
      hasLastStage &&
      row.cache.last_stage.notification &&
      new Date(row.cache.last_stage.notification_time) < new Date();
    const type = this.calcType();
    return (
      <tr type={type}>
        {this.props.smode ? (
          <td>
            {row.id !== currentID ? (
              <button onClick={this.select} title="выбрать родителем">
                {row.id === this.props.value ? (
                  <img
                    src="/img/decline.png"
                    className="menu"
                    alt="отклонить"
                  />
                ) : (
                  <img src="/img/accept.png" className="menu" alt="принять" />
                )}
              </button>
            ) : null}
          </td>
        ) : (
          <td>
            <ShowIfPerm value="citizen-journal:appeal:inspector:u">
              <FakeButton url={url}>
                <img
                  src="/img/edit.png"
                  className="menu"
                  alt="редактор"
                  title="открыть в редакторе"
                />
              </FakeButton>
            </ShowIfPerm>
            <button onClick={this.getExcel} title="скачать данные заявки">
              <img src="/img/excel.png" className="menu" alt="скачать" />
            </button>
          </td>
        )}
        <td className="centred">{row.number}</td>
        <td className="centred">{hasCache ? row.cache.inspectors : ""}</td>
        <td className="centred">
          <ElementPretty value={row.has_parent} type="bool" />
        </td>

        <td>
          <ElementPretty value={row.registration} type="date" />
        </td>
        <td className="centred">{row.data.address}</td>
        <td className="centred">{row.data.object_name}</td>
        <td className="centred">{row.data.residence}</td>
        <td className="centred">{row.data.fullname}</td>
        <td className="centred">{row.data.district}</td>
        <td className="centred">{row.data.microdistrict}</td>
        <td className="centred">{row.data.phone}</td>
        <td className="centred">{row.data.reason}</td>
        <td className="centred">
          <ElementPretty value={row.registrar_type} type="registrar" />
        </td>
        <td className="centred">
          <ElementPretty value={row.accepted} type="accepted" />
        </td>
        <td className="centred">
          <ElementPretty value={row.tags} type="join" />
        </td>
        <td className="centred">
          {hasCache ? (
            <ElementPretty value={row.cache.has_stage} type="bool" />
          ) : null}
        </td>
        <td className="centred">{hasCache ? row.cache.visit_count : ""}</td>
        <td className="centred">{hasCache ? row.cache.phase : ""}</td>
        <td className="centred">{hasCache ? row.cache.phase_public : ""}</td>
        <td className="centred" type={hasAlertLastStage ? "alert" : "none"}>
          {hasLastStage && row.cache.last_stage.notification ? (
            <ElementPretty
              value={row.cache.last_stage.notification_time}
              type="date"
            />
          ) : null}
        </td>
        <td className="centred">
          {row.has_parent === true
            ? ""
            : row.child_ids
            ? row.child_ids.length
            : 0}
        </td>
        <td className="centred">
          {hasLastStage ? row.cache.last_stage.result : ""}
        </td>
        <td className="centred">
          {hasLastStage ? row.cache.last_stage.excesses : ""}
        </td>
        <td className="centred">
          {hasLastStage ? row.cache.last_stage.info_redirect : ""}
        </td>
      </tr>
    );
  }
}
InspectorAppealViewRow.contextType = NotificationContext;
