import React from "react";
import { IsMobile } from "./context";
import { Refresher } from "./refresher";
import { DateTimePicker } from "./service";

// базовый фильтр (иконка)
class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
  }
  show() {
    this.props.onChange(!this.props.value);
  }
  render() {
    const background = this.props.use ? "#ff9800" : "";
    const show = this.props.value;
    return (
      <div style={{ display: "inline", verticalAlign: "middle" }}>
        <button
          style={{
            background: background,
          }}
          onClick={this.show}
          title="это фильтр"
        >
          <img
            src="/img/filter.png"
            style={{
              height: this.props.height + "px",
              verticalAlign: "middle",
            }}
            alt="фильтр"
          />
        </button>
        <div style={{ display: show ? "inline" : "none" }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
Filter.defaultProps = {
  use: false,
  height: 10,
  value: false,
  update: () => {},
};

// текстовый фильтр (строка для ввода)
export class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
  }
  reset() {
    this.props.onChange("");
    this.setState({ show: false });
  }
  update(e) {
    this.props.onChange(e.target.value);
  }
  render() {
    const use = this.props.value ? true : false;
    return (
      <Filter
        use={use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <input value={this.props.value} onChange={this.update} />
        <br />
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}
TextFilter.defaultProps = { value: "" };

// числовой фильтр (строка для ввода)
export class NumberFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
  }
  reset() {
    this.props.onChange(null);
    this.setState({ show: false });
  }
  update(e) {
    this.props.onChange(parseInt(e.target.value));
  }
  render() {
    const use = this.props.value ? true : false;
    return (
      <Filter
        use={use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <input type="number" value={this.props.value} onChange={this.update} />
        <br />
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}
NumberFilter.defaultProps = { value: null };

// фильтр выбора (выпадающий список)
export class ChoiseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      use: false,
      defaultValue: props.value,
    };
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
  }
  reset() {
    this.props.onChange(this.state.defaultValue);
    this.setState({ use: false, show: false });
  }
  update(e) {
    let v = e.target.value;
    this.props.onChange(v);
    this.setState({ use: true });
  }
  render() {
    return (
      <Filter
        use={this.state.use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <select value={this.props.value} onChange={this.update}>
          {this.props.info.map((e) => (
            <option key={e.k} value={e.k} title={e.v}>
              {e.v}
            </option>
          ))}
        </select>
        <br />
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}

// фильтр да/нет (выпадающий выбор)
export class BoolFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      use: false,
      defaultValue: props.value,
    };
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
  }
  reset() {
    this.props.onChange(this.state.defaultValue);
    this.setState({ use: false, show: false });
  }
  update(e) {
    let v = parseInt(e.target.value);
    this.props.onChange(v);
    this.setState({ use: true });
  }
  render() {
    return (
      <Filter
        use={this.state.use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <select value={this.props.value} onChange={this.update}>
          <option value={0}>игнорировать</option>
          <option value={1}>да</option>
          <option value={2}>нет</option>
        </select>
        <br />
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}

// фильтр множественного выбора (отметить несколько чекбоксов)
export class MultiChoiseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      use: false,
      defaultValue: props.value,
    };
    this.reset = this.reset.bind(this);
    this.update = this.update.bind(this);
  }
  reset() {
    this.props.onChange(this.state.defaultValue);
    this.setState({ use: false, show: false });
  }
  update(v) {
    const value = this.props.value;
    let tmp = [...value];
    if (value.includes(v)) {
      tmp = value.filter((e) => e !== v);
    } else {
      tmp.push(v);
    }
    this.props.onChange(tmp);
    this.setState({ use: true });
  }
  render() {
    return (
      <Filter
        use={this.state.use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <br />
        <ul
          style={{ display: "inline-block", listStyle: "none", padding: "0px" }}
        >
          {this.props.info.map((e) => (
            <li key={e.k} style={{ whiteSpace: "nowrap", textAlign: "left" }}>
              <label>
                <input
                  type="checkbox"
                  checked={this.props.value.includes(e.k)}
                  onChange={() => this.update(e.k)}
                />
                {e.v}
              </label>
            </li>
          ))}
        </ul>
        <br />
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}

// фильтр даты/времени
export class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      use: false,
      defaultValue: props.value,
      type: this.parse(props.value),
    };
    this.reset = this.reset.bind(this);
    this.updateType = this.updateType.bind(this);
    this.updateMin = this.updateMin.bind(this);
    this.updateMax = this.updateMax.bind(this);
  }
  reset() {
    this.props.onChange(this.state.defaultValue);
    this.setState({ type: 0, use: false, show: false });
  }
  parse(value) {
    if (value === undefined) {
      return 0;
    }
    return value.min !== undefined && value.max !== undefined
      ? 3
      : value.min !== undefined
      ? 1
      : value.max !== undefined
      ? 2
      : 0;
  }
  updateType(e) {
    const v = parseInt(e.target.value);
    this.update(this.props.value.min, this.props.value.max, v);
    this.setState({ type: v, use: true });
  }
  updateMin(e) {
    this.update(e, this.props.value.max, this.state.type);
    this.setState({ use: true });
  }
  updateMax(e) {
    this.update(this.props.value.min, e, this.state.type);
    this.setState({ use: true });
  }
  update(min, max, type) {
    let tmp = {};
    if (type === 1 || type === 3) {
      tmp.min = min || new Date().toJSON();
    }
    if (type === 2 || type === 3) {
      tmp.max = max || new Date().toJSON();
    }
    this.props.onChange(tmp);
  }
  render() {
    return (
      <Filter
        use={this.state.use}
        value={this.state.show}
        onChange={(e) => this.setState({ show: e })}
      >
        <select value={this.state.type} onChange={this.updateType}>
          <option value={0}>не используются</option>
          <option value={1}>позже</option>
          <option value={2}>раньше</option>
          <option value={3}>диапазон</option>
        </select>
        {this.state.type === 1 || this.state.type === 3 ? (
          <div>
            c
            <DateTimePicker value={this.props.min} onChange={this.updateMin} />
          </div>
        ) : (
          ""
        )}
        {this.state.type === 2 || this.state.type === 3 ? (
          <div>
            до
            <DateTimePicker value={this.props.max} onChange={this.updateMax} />
          </div>
        ) : (
          ""
        )}
        <button style={{ color: "red", width: "158px" }} onClick={this.reset}>
          Очистить фильтр
        </button>
      </Filter>
    );
  }
}
DateFilter.defaultProps = { value: {} };

// пагинатор (панель с номерами страниц)
export class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      use: false,
      defaultValue: props.value,
    };
    this.reset = this.reset.bind(this);
    this.setOffset = this.setOffset.bind(this);
    this.setReverse = this.setReverse.bind(this);
    this.setCount = this.setCount.bind(this);
  }
  reset() {
    this.props.onChange(this.state.defaultValue);
    this.setState({ use: false, show: false });
  }
  update(fieldName, value) {
    let tmp = { ...this.props.value };
    tmp[fieldName] = value;
    this.props.onChange(tmp);
  }
  setOffset(offset) {
    this.update("offset", offset);
  }
  setReverse() {
    this.update("reverse", !this.props.value.reverse);
    this.setState({ use: true });
  }
  setCount(e) {
    this.update("count", parseInt(e.target.value));
    this.setState({ use: true });
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.props.get();
    }
  }
  calcPages(cur, max, count) {
    const leftPageCount = IsMobile ? 1 : 3;
    const middlePageCount = 5;
    const rightPageCount = IsMobile ? 1 : 3;
    const len = leftPageCount + middlePageCount + rightPageCount + 2;
    let pages = [];
    if (max <= len) {
      for (let i = 0; i < max; i++) {
        pages.push({
          page: i + 1,
          offset: i * count,
        });
      }
      return pages;
    }
    // генерация первой 3-ки страниц
    for (let i = 0; i < leftPageCount; i++) {
      pages.push({
        page: i + 1,
        offset: i * count,
      });
    }
    // генерация центральных страниц которые близко к первым 3-м страницам
    if (cur < leftPageCount + middlePageCount) {
      for (let i = leftPageCount; i < leftPageCount + middlePageCount; i++) {
        pages.push({
          page: i + 1,
          offset: i * count,
        });
      }
      // добавляем разделитель
      pages.push({
        page: -1,
        offset: 0,
      });
    }
    // генерация центральных страниц которые близко к последним 3-м страницам
    if (cur > max + 1 - middlePageCount - rightPageCount) {
      // добавляем разделитель
      pages.push({
        page: -1,
        offset: 0,
      });
      for (
        let i = max - middlePageCount - rightPageCount;
        i < max - rightPageCount;
        i++
      ) {
        pages.push({
          page: i + 1,
          offset: i * count,
        });
      }
    }
    // генерация центральных страниц в нормальном случае
    if (
      cur >= leftPageCount + middlePageCount &&
      cur <= max + 1 - middlePageCount - rightPageCount
    ) {
      // добавляем разделитель
      pages.push({
        page: -1,
        offset: 0,
      });
      for (
        let i = cur - Math.ceil(middlePageCount / 2);
        i < cur + Math.floor(middlePageCount / 2);
        i++
      ) {
        pages.push({
          page: i + 1,
          offset: i * count,
        });
      }
      // добавляем разделитель
      pages.push({
        page: -1,
        offset: 0,
      });
    }
    // генерация последней тройки страниц
    for (let i = max - rightPageCount; i < max; i++) {
      pages.push({
        page: i + 1,
        offset: i * count,
      });
    }
    return pages;
  }
  render() {
    const pageInfo = this.props.info;
    const pages = this.calcPages(
      pageInfo.current,
      pageInfo.count,
      this.props.value.count
    );
    return (
      <div className="paginator">
        {pages.map((e, ind) => (
          <Page
            page={e.page}
            offset={e.offset}
            key={e.offset + "" + ind}
            select={e.page === pageInfo.current}
            empty={e.page === -1}
            onChange={this.setOffset}
          />
        ))}
        {IsMobile ? null : (
          <p style={{ display: "inline", marginRight: "5px" }}>
            Всего записей: {pageInfo.items_total}
          </p>
        )}
        <Filter
          use={false}
          value={this.state.show}
          onChange={(e) => this.setState({ show: e })}
          height={this.props.height}
        >
          <div className="filter">
            <div
              onClick={this.setReverse}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <img
                style={{ height: "20px" }}
                src={
                  this.props.value.reverse
                    ? "/img/arrow_up.png"
                    : "/img/arrow_down.png"
                }
                alt="сортировка"
                title={
                  "Сортировка: сначала" +
                  (this.props.value.reverse ? " новые" : " старые")
                }
              />
            </div>
            <select
              style={{ marginLeft: "5px" }}
              value={this.props.value.count}
              onChange={this.setCount}
              title="Количество на странице"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Refresher get={this.props.get} />
          </div>
        </Filter>
        {this.props.children}
      </div>
    );
  }
}
Paginator.defaultProps = {
  height: 10,
};

// функция рендера номера страницы
function Page(props) {
  const type = props.select ? "selected" : props.empty ? "empty" : "";
  return (
    <div
      className="page"
      onClick={() => (props.page > 0 ? props.onChange(props.offset) : null)}
      type={type}
    >
      {props.page > 0 ? props.page : "..."}
    </div>
  );
}
