import { Link } from "react-router-dom";
import { Menu } from "./menu.js";
import { AccountContext, AuthContext } from "../base/context.js";
import { useContext } from "react";

// компонет рендера шапки сайта
export function Header() {
  const account = useContext(AccountContext);
  const auth = useContext(AuthContext);
  return (
    <div className="header">
      <div className="left">
        <div className="logo">
          <a href="http://krasecology.ru" className="ke">
            <img src="/img/sheet.png" width="40px" alt="logo" />
          </a>
          <Link to="/">
            <h1 className="inline-block">Журналы обращений граждан</h1>
          </Link>
        </div>
        <Menu />
      </div>
      {account.logged ? (
        <div className="info">
          <b>Привет!</b>
          <br />
          <Link to="/account/info">{account.accountInfo.username}</Link>
          <br />
          <button className="text-red" onClick={auth.logout}>
            Выйти
          </button>
        </div>
      ) : (
        <div className="info">
          <Link to="/login">
            <h2>войти</h2>
          </Link>
        </div>
      )}
    </div>
  );
}
