// компонент рендера сообщения (уведомление)
export function Message(props) {
  const text = props.message.message
    ? props.message.message
    : props.message.header;
  const cls = selectClass(props.message.tp);
  let imgSrc = "/img/info.png";
  switch (props.message.tp) {
    case 1:
      imgSrc = "/img/info.png";
      break;
    case 2:
      imgSrc = "/img/error.png";
      break;
    case 3:
      imgSrc = "/img/load.gif";
      break;
    default:
      break;
  }
  return (
    <div className="message">
      <table className={cls}>
        <tbody>
          <tr>
            <td className="left">
              <img
                style={{ width: "30px" }}
                alt={props.message.header}
                src={imgSrc}
              />
            </td>
            <td className="middle">{text}</td>
            <td className="right">
              <button onClick={props.message.Close} title="Закрыть">
                X
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

// функция выбора класса (css) сообщения
function selectClass(tp) {
  switch (tp) {
    case 1:
      return "ok";
    case 2:
      return "err";
    case 3:
      return "load";
    default:
      break;
  }
  return "info";
}
