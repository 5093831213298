import { Message } from "./message.js";

// функция рендера зоны уведомлений
export function Notification(props) {
  return (
    <div
      className="notification"
      style={{
        position: "fixed",
        right: "0px",
        bottom: "0px",
      }}
    >
      <ul>
        {props.messages.reverse().map((mess) => (
          <li style={{ listStyleType: "none" }} key={mess.id}>
            <Message message={mess}></Message>
          </li>
        ))}
      </ul>
    </div>
  );
}
