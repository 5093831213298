import React from "react";
import { DataContext, NotificationContext, ZeroID } from "base/context";
import {
  BoolFilter,
  ChoiseFilter,
  DateFilter,
  MultiChoiseFilter,
  Paginator,
  TextFilter,
} from "base/filter";
import { Load } from "base/load";
import { ElementPretty, FakeButton, ShowIfPerm } from "base/service";

// страница просмотра объектов ОНВОС
export class ObjectViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        category: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getExcel = this.getExcel.bind(this);
    this.timeID = null;
  }
  componentDidMount() {
    this.get();
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка ОНВОС",
      "/api/object/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  getExcel() {
    new Load(
      this.context,
      "Получение списка ОНВОС EXCEL",
      "/api/object/filter-excel",
      this.state.filter
    )
      .SetErr()
      .Load(true)
      .then((data) => {
        let url = window.URL.createObjectURL(new Blob([data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
  render() {
    return (
      <div>
        <h1>Список объектов ОНВОС</h1>
        <hr />
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        >
          <ShowIfPerm value="citizen-journal:object:c">
            <FakeButton url={"/object/edit/new"} style={{ marginLeft: "20px" }}>
              <b className="text-green">Новый</b>
            </FakeButton>
          </ShowIfPerm>
          <button
            onClick={this.getExcel}
            title="скачать ОНВОС"
            style={{ marginLeft: "10px" }}
          >
            <img
              src="/img/excel.png"
              style={{ verticalAlign: "middle", height: "15px" }}
              alt="скачать"
            />
            Скачать Excel
          </button>
        </Paginator>
        <hr />
        <table className="view">
          <thead className="double object">
            <ObjectViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody>
            {this.state.data.map((e, ind) => (
              <ObjectViewRow key={ind} row={e} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
ObjectViewPage.contextType = NotificationContext;

// шапка таблицы объектов ОНВОС
export class ObjectViewHead extends React.Component {
  render() {
    return (
      <>
        <tr>
          <td>Действия</td>
          <td>Время занесения в базу</td>
          <td>Наименование хозяйствующего субъекта</td>
          <td>Наименование объекта негативного воздействия</td>
          <td>ИНН</td>
          <td>
            Адрес местонахождения эксплуатируемого объекта негативного
            воздействия
          </td>
          <td>Широта</td>
          <td>Долгота</td>
          <td>Категория объекта негативного воздействия</td>
          <td>Поднадзорность (региональный/федеральный надзор)</td>
          <td>
            Включен в перечень предприятий, обязанных проводить мероприятия в
            период НМУ
          </td>
          <td>Временный объект</td>
          <td>На учете ОНВОС</td>
          <td>Заметка</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <DateFilter
              value={this.props.filter.registration}
              onChange={(e) => this.props.updateFilter("registration", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.name}
              onChange={(e) => this.props.updateFilter("name", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.object_name}
              onChange={(e) => this.props.updateFilter("object_name", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.inn}
              onChange={(e) => this.props.updateFilter("inn", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.address}
              onChange={(e) => this.props.updateFilter("address", e)}
            />
          </td>
          <td></td>
          <td></td>
          <td>
            <MultiChoiseFilter
              value={this.props.filter.category}
              info={this.context.category}
              onChange={(e) => this.props.updateFilter("category", e)}
            />
          </td>
          <td>
            <ChoiseFilter
              value={this.props.filter.oversight}
              info={this.context.oversight}
              onChange={(e) => this.props.updateFilter("oversight", e)}
            />
          </td>
          <td>
            <BoolFilter
              value={this.props.filter.nmu}
              onChange={(e) => this.props.updateFilter("nmu", e)}
            />
          </td>
          <td>
            <BoolFilter
              value={this.props.filter.temporary}
              onChange={(e) => this.props.updateFilter("temporary", e)}
            />
          </td>
          <td>
            <BoolFilter
              value={this.props.filter.registered}
              onChange={(e) => this.props.updateFilter("registered", e)}
            />
          </td>
          <td>
            <TextFilter
              value={this.props.filter.note}
              onChange={(e) => this.props.updateFilter("note", e)}
            />
          </td>
        </tr>
      </>
    );
  }
}
ObjectViewHead.contextType = DataContext;

// строка таблицы объектов ОВНОС
export class ObjectViewRow extends React.Component {
  constructor(props) {
    super(props);
    this.del = this.del.bind(this);
    this.select = this.select.bind(this);
  }
  del() {
    if (window.confirm("Это действие удалит объект, продолжить?") === false) {
      return;
    }
    new Load(
      this.context,
      "Удаление объекта",
      "/api/object/delete",
      JSON.stringify(this.props.row.id)
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.props.get());
  }
  select() {
    if (this.props.onChange === undefined) {
      return;
    }
    if (this.props.row.id === this.props.value) {
      this.props.onChange(ZeroID);
    } else {
      this.props.onChange(this.props.row.id);
    }
  }
  calcType() {
    const row = this.props.row;
    const smode = this.props.smode ? true : false;
    if (smode && row.id === this.props.value) {
      return "selected";
    }
    return "none";
  }
  render() {
    const row = this.props.row;
    const url = "/object/edit/" + row.id;
    const type = this.calcType();
    return (
      <tr type={type}>
        {this.props.smode ? (
          <td>
            <button onClick={this.select} title="выбрать">
              {row.id === this.props.value ? (
                <img src="/img/decline.png" className="menu" alt="отклонить" />
              ) : (
                <img src="/img/accept.png" className="menu" alt="принять" />
              )}
            </button>
          </td>
        ) : (
          <td>
            <ShowIfPerm value="citizen-journal:object:u">
              <FakeButton url={url}>
                <img
                  src="/img/edit.png"
                  className="menu"
                  alt="редактор"
                  title="открыть в редакторе"
                />
              </FakeButton>
            </ShowIfPerm>
            <ShowIfPerm value="citizen-journal:object:d">
              <button onClick={this.del} title="удалить">
                <img src="/img/delete.png" className="menu" alt="удаление" />
              </button>
            </ShowIfPerm>
          </td>
        )}
        <td>
          <ElementPretty value={row.registration} type="date" />
        </td>
        <td className="centred">{row.name}</td>
        <td className="centred">{row.object_name}</td>
        <td className="centred">{row.inn}</td>
        <td className="centred">{row.address}</td>
        <td className="centred">{row.lat}</td>
        <td className="centred">{row.lon}</td>
        <td className="centred">{row.category}</td>
        <td className="centred">{row.oversight}</td>
        <td className="centred">
          <ElementPretty value={row.nmu} type="bool" />
        </td>
        <td className="centred">
          <ElementPretty value={row.temporary} type="bool" />
        </td>
        <td className="centred">
          <ElementPretty value={row.registered} type="bool" />
        </td>
        <td className="centred">{row.note}</td>
      </tr>
    );
  }
}
ObjectViewRow.contextType = NotificationContext;
