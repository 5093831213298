import { Redirect, Route, Switch } from "react-router-dom";
import { AccountContext } from "../base/context.js";
import { ErrorPage, NotFoundPage, SuccessPage } from "./service.js";
import { AccountInfoPage } from "./account/info.js";
import { PhasesPage } from "./admin/phases.js";
import { PhasesPublicPage } from "./admin/phasesPublic.js";
import { StaffPage } from "./admin/staff.js";
import { CitizenAppealViewPage } from "./citizen/appeal.js";
import { LoginPage } from "./account/login.js";
import { MainPage } from "./main.js";
import "./style.css";
import { VisitViewPage } from "./visit/view.js";
import { VisitEditPage } from "./visit/edit.js";
import { ObjectViewPage } from "./object/view.js";
import { ObjectEditPage } from "./object/edit.js";
import { OperatorAppealViewPage } from "./operator/view.js";
import { InspectorAppealViewPage } from "./inspector/view.js";
import { InspectorAppealEditPage } from "./inspector/edit.js";
import { AnalyzerPage } from "./analyze/analyzer.js";
import { ModeratorAppealViewPage } from "./moderator/view.js";
import { ShowIfPerm } from "../base/service.js";
import { LogsPage } from "./admin/logs.js";
import { ActionPage } from "./admin/action.js";
import { CitizenMapViewPage } from "./citizen/map.js";
import { CitizenPersonalViewPage } from "./citizen/personal.js";
import { CitizenServeAppealPage } from "./citizen/editor.js";
import { useContext } from "react";
import { CitizenServePublicOrgPage } from "./citizen/pub-org-editor.js";

// компонент рендера выбора страниц
export function Pages() {
  const account = useContext(AccountContext);
  return (
    <div
      style={{
        margin: "10px",
      }}
    >
      <Switch>
        {/* технические страницы */}
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/success">
          <SuccessPage />
        </Route>
        {/* страница логина */}
        <Route path="/login">
          {account.logged ? <Redirect to="/" /> : <LoginPage />}
        </Route>
        {/* информация об аккаунте */}
        <Route path="/account/info">
          <AccountInfoPage />
        </Route>
        {/* админские страницы */}
        <Route path="/admin/action">
          <ShowIfPerm value="citizen-journal:tech:m" redirect={true}>
            <ActionPage />
          </ShowIfPerm>
        </Route>
        <Route path="/admin/staff">
          <ShowIfPerm value="citizen-journal:tech:m" redirect={true}>
            <StaffPage />
          </ShowIfPerm>
        </Route>
        <Route path="/admin/phase">
          <ShowIfPerm value="citizen-journal:tech:m" redirect={true}>
            <PhasesPage />
          </ShowIfPerm>
        </Route>
        <Route path="/admin/public-phase">
          <ShowIfPerm value="citizen-journal:tech:m" redirect={true}>
            <PhasesPublicPage />
          </ShowIfPerm>
        </Route>
        <Route path="/admin/logs">
          <ShowIfPerm value="citizen-journal:tech:m" redirect={true}>
            <LogsPage />
          </ShowIfPerm>
        </Route>
        {/* страницы для гражданина */}
        <Route path="/citizen/create/pub-org">
          {account.logged ? (
            <CitizenServePublicOrgPage />
          ) : (
            <LoginPage requareAuth={true} />
          )}
        </Route>
        <Route path="/citizen/create">
          {account.logged ? (
            <CitizenServeAppealPage />
          ) : (
            <LoginPage requareAuth={true} />
          )}
        </Route>
        <Route path="/citizen/personal">
          {account.logged ? (
            <CitizenPersonalViewPage />
          ) : (
            <LoginPage requareAuth={true} />
          )}
        </Route>
        <Route path="/citizen/map/view">
          <CitizenMapViewPage />
        </Route>
        <Route path="/citizen/appeal/view">
          <CitizenAppealViewPage />
        </Route>
        <Route path="/appeal/wv/list">
          <Redirect to="/citizen/appeal/view" />
        </Route>
        {/* страницы выездов */}
        <Route path="/visit/view">
          <ShowIfPerm value="citizen-journal:visit:r" redirect={true}>
            <VisitViewPage />
          </ShowIfPerm>
        </Route>
        <Route path="/visit/edit/:id">
          <ShowIfPerm
            any={["citizen-journal:visit:c", "citizen-journal:visit:u"]}
            redirect={true}
          >
            <VisitEditPage />
          </ShowIfPerm>
        </Route>
        {/* страницы объектов ОНВОС */}
        <Route path="/object/view">
          <ShowIfPerm value="citizen-journal:object:r" redirect={true}>
            <ObjectViewPage />
          </ShowIfPerm>
        </Route>
        <Route path="/object/edit/:id">
          <ShowIfPerm
            any={["citizen-journal:object:c", "citizen-journal:object:u"]}
            redirect={true}
          >
            <ObjectEditPage />
          </ShowIfPerm>
        </Route>
        {/* страницы обращений */}
        <Route path="/operator/view">
          <ShowIfPerm value="citizen-journal:appeal:r" redirect={true}>
            <OperatorAppealViewPage />
          </ShowIfPerm>
        </Route>
        <Route path="/inspector/view">
          <ShowIfPerm value="citizen-journal:appeal:r" redirect={true}>
            <InspectorAppealViewPage />
          </ShowIfPerm>
        </Route>
        <Route path="/inspector/edit/:id">
          <ShowIfPerm
            value="citizen-journal:appeal:inspector:u"
            redirect={true}
          >
            <InspectorAppealEditPage />
          </ShowIfPerm>
        </Route>
        <Route path="/analyzer">
          <ShowIfPerm value="citizen-journal:appeal:r" redirect={true}>
            <AnalyzerPage />
          </ShowIfPerm>
        </Route>
        <Route path="/moderator/view">
          <ShowIfPerm value="citizen-journal:appeal:r" redirect={true}>
            <ModeratorAppealViewPage />
          </ShowIfPerm>
        </Route>
        {/* 404 страница */}
        <Route path="/:any">
          <NotFoundPage />
        </Route>
        {/* главная страница, да она должна идти после 404 для правильной маршрутизации */}
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </div>
  );
}
