import React from "react";

export class Refresher extends React.Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      value: 10 * 60,
    };
    this.resetRefresh = this.resetRefresh.bind(this);
  }
  componentDidMount() {
    this.resetRefresh();
  }
  componentWillUnmount() {
    if (this.id != null) {
      clearInterval(this.id);
    }
  }
  componentDidUpdate(prevProp) {
    if (this.props.get !== prevProp.get) {
      this.resetRefresh();
    }
  }
  resetRefresh() {
    if (this.id != null) {
      clearInterval(this.id);
    }
    if (this.state.value > 0) {
      this.id = setInterval(this.props.get, this.state.value * 1000);
    }
  }
  render() {
    return (
      <select
        value={this.state.value}
        title="Период времени автообновления"
        onChange={(e) =>
          this.setState({ value: e.target.value }, () => this.resetRefresh())
        }
        style={this.props.style}
        className={this.props.className}
      >
        <option value={60}>1 минута</option>
        <option value={5 * 60}>5 минут</option>
        <option value={10 * 60}>10 минут</option>
        <option value={20 * 60}>20 минут</option>
        <option value={40 * 60}>40 минут</option>
        <option value={60 * 60}>60 минут</option>
      </select>
    );
  }
}
