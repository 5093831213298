import React, { useState } from "react";
import { IsMobile, NotificationContext } from "base/context";
import {DateFilter, NumberFilter, Paginator, TextFilter} from "base/filter";
import { Load } from "base/load";
import { ElementPretty } from "base/service";

// страница отображения звонков на горячую линию для населения
export class CitizenAppealViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        number:null,
        time: {},
        district: "",
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  updateFilter(key, value, getAfter = true) {
    this.setState(
      (state) => ({ filter: { ...state.filter, [key]: value } }),
      () => (getAfter ? this.get() : null)
    );
  }
  get() {
    new Load(
      this.context,
      "Получение списка обращений",
      "/api/citizen/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  render() {
    return (
      <div>
        <h1>Список обращений</h1>
        Телефон горячей линии 8-800-20-11-116, (391) 21-21-706.
        <hr />
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e, false)}
          height={8}
        />
        <hr />
        <table className="view" style={{ width: "100%" }}>
          <thead className="single citizen">
            <tr>
              <td>Номер обращения</td>
              <td>Время регистрации</td>
              <td>Район</td>
              {IsMobile ? null : (
                <>
                  <td>Причина звонка</td>
                  <td>Статус</td>
                  <td>Результат</td>
                  <td>Нарушения</td>
                  <td>Перенаправление информации по компетенции</td>
                </>
              )}
            </tr>
            <tr>
              <td>
                <NumberFilter
                  value={this.state.filter.number}
                  onChange={(e) => this.updateFilter("number", e)}
                />
              </td>
              <td>
                <DateFilter
                  value={this.state.filter.time}
                  onChange={(e) => this.updateFilter("time", e)}
                />
              </td>
              <td>
                <TextFilter
                  value={this.state.filter.district}
                  onChange={(e) => this.updateFilter("district", e)}
                />
              </td>
              {IsMobile ? null : (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              )}
            </tr>
          </thead>
          <tbody className="citizen">
            {this.state.data.map((e, ind) => (
              <CitizenAppealViewRow key={ind} row={e} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
CitizenAppealViewPage.contextType = NotificationContext;

// функция для рендера строки с информацией об обращении
function CitizenAppealViewRow(props) {
  const lastStage =
    props.row.cache && props.row.cache.last_stage
      ? props.row.cache.last_stage
      : {
          result: "",
          excesses: "",
          info_redirect: "",
        };
  const phase = props.row.cache ? props.row.cache.phase_public : "";
  const [show, setShow] = useState(false);
  const click = () => {
    setShow(true);
  };
  const district =
    (props.row.data.district ? props.row.data.district : "") +
    (props.row.data.microdistrict
      ? " (" + props.row.data.microdistrict + ") "
      : "");
  if (IsMobile && show) {
    return (
      <tr>
        <td>
          <div className="full-screen">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td className="info">Номер обращения</td>
                    <td className="data">{props.row.number}</td>
                  </tr>
                  <tr>
                    <td className="info">Время регистрации</td>
                    <td className="data">
                      <ElementPretty
                        value={props.row.registration}
                        type="date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="info">Район</td>
                    <td className="data">{district}</td>
                  </tr>
                  <tr>
                    <td className="info">Причина звонка</td>
                    <td className="data">{props.row.data.reason}</td>
                  </tr>
                  <tr>
                    <td className="info">Статус</td>
                    <td className="data">{phase}</td>
                  </tr>
                  <tr>
                    <td className="info">Результат</td>
                    <td className="data">{lastStage.result}</td>
                  </tr>
                  <tr>
                    <td className="info">Нарушения</td>
                    <td className="data">{lastStage.excesses}</td>
                  </tr>
                  <tr>
                    <td className="info">
                      Перенаправление информации по компетенции
                    </td>
                    <td className="data">{lastStage.info_redirect}</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <button
                        className="text-red"
                        onClick={() => setShow(false)}
                        style={{ width: "100%" }}
                      >
                        Закрыть
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }
  return (
    <tr onClick={click}>
      <td className="centred">{props.row.number}</td>
      <td>
        <ElementPretty value={props.row.registration} type="date" />
      </td>
      <td className="centred">{district}</td>
      {IsMobile ? null : (
        <>
          <td className="centred">{props.row.data.reason}</td>
          <td className="centred">{phase}</td>
          <td className="centred">{lastStage.result}</td>
          <td className="centred">{lastStage.excesses}</td>
          <td className="centred">{lastStage.info_redirect}</td>
        </>
      )}
    </tr>
  );
}
