import React, { useContext, useState } from "react";
import {
  AccountContext,
  IsMobile,
  NotificationContext,
  ZeroID,
} from "base/context";
import { Load } from "base/load";
import ymaps from "ymaps";
import { LoginPage } from "pages/account/login";

// страница для отображения карты ОНВОС
export class CitizenMapViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      info: [],
      mapLoad: false,
      mapAppeal: false,
      selectObject: null,
    };
    this.mapRef = React.createRef();
    this.get = this.get.bind(this);
    this.ymap = null;
    this.yandex = null;
    this.applicationButton = null;
    this.timerID = null;
  }
  addGeoObject() {
    if (!this.state.mapLoad) {
      return;
    }
    let geoObjects = [];
    this.state.data.forEach((nob) => {
      let gob = new this.yandex.Placemark(
        [nob.lat, nob.lon],
        {
          nob: nob,
          balloonContentHeader: nob.name,
          hintContent: nob.name,
        },
        {
          hasBalloon: false,
          preset: nob.nmu ? "islands#dotIcon" : "islands#icon",
          iconColor: nob.phase_color || "#808080",
        }
      );
      gob.events.add("click", () => {
        this.setState({ selectObject: nob });
      });
      geoObjects.push(gob);
    });
    let clusterer = new this.yandex.Clusterer({
      clusterIconLayout: "default#pieChart",
      clusterIconPieChartRadius: 20,
      clusterIconPieChartCoreRadius: 15,
      clusterIconPieChartStrokeWidth: 3,
      hasBalloon: false,
    });
    clusterer.add(geoObjects);
    this.ymap.geoObjects.removeAll();
    this.ymap.geoObjects.add(clusterer);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  componentDidMount() {
    this.timerID = setInterval(() => this.get(), 10 * 60 * 1000);
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?apikey=b474a94c-35be-48bc-b4a7-6a01831ec437&load=package.full&lang=ru-RU"
      )
      .then((maps) => {
        this.yandex = maps;
        this.ymap = new maps.Map(this.mapRef.current, {
          center: [56.029908, 92.912458],
          zoom: 11,
        });
        this.applicationButton = new maps.control.Button({
          data: {
            content: "Пожаловаться",
          },
          options: {
            maxWidth: 500,
            selectOnClick: false,
          },
        });
        this.applicationButton.events.add("click", () => {
          this.setState({ mapAppeal: true });
        });
        this.ymap.controls.add(this.applicationButton);
        this.setState({ mapLoad: true }, () => this.get());
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  }
  get() {
    return new Promise((resolve, reject) => {
      new Load(
        this.context,
        "Получение списка объектов",
        "/api/citizen/map-data"
      )
        .SetErr()
        .Load()
        .then((data) => {
          this.setState(
            {
              data: data.data,
              info: data.info,
            },
            () => {
              this.addGeoObject();
              resolve();
            }
          );
        })
        .catch((err) => reject(err));
    });
  }
  render() {
    return (
      <div>
        <div
          ref={this.mapRef}
          style={{
            width: "calc(100vw - 20px)",
            height: IsMobile ? "calc(100vh - 240px)" : "calc(100vh - 110px)",
          }}
        ></div>
        {this.state.selectObject ? (
          <Baloon
            object={this.state.selectObject}
            close={() => this.setState({ selectObject: null })}
          />
        ) : null}
        <Legend value={this.state.info} />
        {this.state.mapAppeal ? (
          <Appeal
            object={this.state.selectObject}
            close={() => this.setState({ mapAppeal: false })}
          />
        ) : null}
      </div>
    );
  }
}
CitizenMapViewPage.contextType = NotificationContext;

// функция дл отрисовки легенды для карты
function Legend(props) {
  const [show, setShow] = useState(true);
  return (
    <div className="map-legend">
      {show ? (
        <>
          <b>Обозначения:</b>
          <br />
          <img
            src="/img/map-example/point.svg"
            style={{ width: "20px" }}
            alt="метка"
          />
          - <i>предприятие</i>
          <br />
          <img
            src="/img/map-example/point-with-dot.svg"
            style={{ width: "20px" }}
            alt="метка с точкой"
          />
          - <i>предприятие, обязанное производить мероприятия в период НМУ</i>
          <br />
          <img
            src="/img/map-example/circle.svg"
            style={{ width: "20px" }}
            alt="круг"
          />
          - <i>визуальное объединение нескольких предприятий</i>
          <br />
          <button
            onClick={() => setShow(false)}
            style={{ position: "absolute", top: "0px", right: "0px" }}
          >
            X
          </button>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td>
                  <b>Цвет этапа на карте:</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {props.value.map((e) => (
                <tr key={e.name}>
                  <td style={{ background: e.color, padding: "5px" }}>
                    {e.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <button onClick={() => setShow(true)}>Показать легенду</button>
      )}
    </div>
  );
}

// функция рендера данных обращения
function Baloon(props) {
  const account = useContext(AccountContext);
  const [show, setShow] = useState(false);
  return (
    <div className="map-baloon">
      <div>
        <ObjectInfo object={props.object} />
        {show ? (
          account.logged ? (
            <Appeal object={props.object} close={() => setShow(false)} />
          ) : (
            <>
              <LoginPage requareAuth={true} />
            </>
          )
        ) : null}
        {account.logged ? (
          <button onClick={() => setShow(true)} className="text-blue">
            Пожаловаться
          </button>
        ) : (
          <h3 className="text-red">
            Чтобы подать жалобу необходимо авторизироваться
          </h3>
        )}
        <button
          onClick={() => props.close()}
          className="text-red"
          style={{ marginBottom: "80px" }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

// функция рендера данных объекта ОНВОС
function ObjectInfo(props) {
  return (
    <div>
      <h1>{props.object.name}</h1>
      <table
        className="view"
        style={{ boxShadow: "none", width: "unset", display: "inline-block" }}
      >
        <tbody>
          <tr>
            <td>Наименование хозяйствующего субъекта</td>
            <td>{props.object.name}</td>
          </tr>
          <tr>
            <td>Наименование объекта негативного воздействия</td>
            <td>{props.object.object_name}</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>{props.object.inn}</td>
          </tr>
          <tr>
            <td>
              Адрес местонахождения эксплуатируемого объекта негативного
              воздействия
            </td>
            <td>{props.object.address}</td>
          </tr>
          <tr>
            <td>Категория объекта негативного воздействия</td>
            <td>{props.object.category}</td>
          </tr>
          <tr>
            <td>Поднадзорность (региональный/федеральный надзор)</td>
            <td>{props.object.oversight}</td>
          </tr>
          <tr>
            <td>
              Включен в перечень предприятий, обязанных проводить мероприятия в
              период НМУ
            </td>
            <td>{props.object.nmu ? "Да" : "Нет"}</td>
          </tr>
          <tr>
            <td>Состоит на учете ОНВОС</td>
            <td>{props.object.registered ? "Да" : "Нет"}</td>
          </tr>
          <tr>
            <td>Количество заявок по данному объекту</td>
            <td>{props.object.appeal_count}</td>
          </tr>
          <tr>
            <td>Текущий этап</td>
            <td>{props.object.phase_name}</td>
          </tr>
          <tr>
            <td>Результат текущего этапа</td>
            <td>{props.object.phase_result}</td>
          </tr>
          <tr>
            <td colSpan="2">Статистика</td>
          </tr>
          {props.object.stat.map((e, i) => (
            <tr key={i}>
              <td style={{ background: e.phase_color }}>{e.phase_name}</td>
              <td>
                {e.main_count} ({e.count})
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
// функция рендера подачи обращения с карты
function Appeal(props) {
  const object = props.object || {};
  const account = useContext(AccountContext);
  const [fullname, setFullname] = useState(account.fullname);
  const [phone, setPhone] = useState(account.phone);
  const [objectAddress, setObjectAddress] = useState(object.address || "");
  const [objectName, setObjectName] = useState(object.name || "");
  const [reason, setReason] = useState("");
  const objectID = object.id || ZeroID;
  const lat = object.lat || 0;
  const lon = object.lon || 0;
  const [confirm, setConfirm] = useState(false);
  const context = useContext(NotificationContext);
  const create = () => {
    if (
      !confirm ||
      !fullname ||
      !objectAddress ||
      !objectName ||
      !phone ||
      !reason
    ) {
      alert("Не заполнены обязательные поля!");
      return;
    }
    new Load(context, "Подача заявки", "/api/citizen/new-map-appeal", {
      fullname: fullname,
      phone: phone,
      object_address: objectAddress,
      object_name: objectName,
      reason: reason,
      object_id: objectID,
      lat: lat,
      lon: lon,
    })
      .SetOk("успешно", null, (id) => "Код вашей заявки: " + id)
      .SetErr()
      .Load()
      .then(() => {
        props.close();
      });
  };
  const red = <span style={{ color: "red" }}>*</span>;
  return (
    <div className="map-appeal">
      <div style={{ display: "inline-block", width: "100%" }}>
        {account.logged ? (
          <table className="inline-block">
            <tbody>
              <tr>
                <td>
                  <input
                    style={{ marginBottom: "3px" }}
                    placeholder="ФИО"
                    className="stdin"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </td>
                <td>{red}</td>
              </tr>
              <tr>
                <td>
                  <input
                    style={{ marginBottom: "3px" }}
                    placeholder="Телефон"
                    className="stdin"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </td>

                <td>{red}</td>
              </tr>
              <tr>
                <td>
                  <textarea
                    placeholder="Наименование объекта негативного воздействия"
                    disabled={object.name > ""}
                    className="stdin"
                    value={objectName}
                    onChange={(e) => setObjectName(e.target.value)}
                  />
                </td>

                <td>{red}</td>
              </tr>
              <tr>
                <td>
                  <textarea
                    placeholder="Адрес объекта негативного воздействия"
                    disabled={object.address > ""}
                    className="stdin"
                    value={objectAddress}
                    onChange={(e) => setObjectAddress(e.target.value)}
                  />
                </td>

                <td>{red}</td>
              </tr>
              <tr>
                <td>
                  <textarea
                    placeholder="Причина обращения"
                    className="stdin"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </td>

                <td>{red}</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      checked={confirm}
                      onChange={(e) => setConfirm(e.target.checked)}
                    />
                    Согласие на обработку персональных данных
                  </label>
                </td>
                <td>{red}</td>
              </tr>
              <tr>
                <td colSpan={2}>{red} - поля обязательные для заполнения</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button onClick={create} className="text-green">
                    Отправить жалобу
                  </button>
                  <button onClick={() => props.close()} className="text-red">
                    Отмена
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
            {/* <LoginPage requareAuth={true} /> */}
            <h1 className="text-red">
              Чтобы подать жалобу необходимо авторизироваться
            </h1>
            <button onClick={() => props.close()} className="text-red">
              Закрыть
            </button>
          </>
        )}
      </div>
    </div>
  );
}
