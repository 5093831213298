import { Link, useLocation } from "react-router-dom";
import { AccountContext, IsMobile } from "base/context";
import React, { useContext, useState } from "react";

// компонент рендера меню сайта (на шапке)
export function Menu() {
  const context = useContext(AccountContext);
  const check = (code) => context.checkPerm(code);
  let menu = [];
  // добавляем админское меню
  if (check("citizen-journal:tech:m")) {
    menu.push({
      name: "Админу",
      link: "/admin/staff",
      sub: [
        { name: "Сотрудники", link: "/admin/staff" },
        { name: "Этапы", link: "/admin/phase" },
        { name: "Публичные этапы", link: "/admin/public-phase" },
        { name: "Логи", link: "/admin/logs" },
        { name: "Действия", link: "/admin/action" },
      ],
    });
  }
  // добавляем меню обращений
  if (
    check("citizen-journal:object:r") ||
    check("citizen-journal:appeal:r") ||
    check("citizen-journal:visit:r")
  ) {
    let subMenu = [];
    if (check("citizen-journal:appeal:r")) {
      subMenu.push({ name: "Список обращений", link: "/operator/view" });
      subMenu.push({ name: "Модерация обращений", link: "/moderator/view" });
      subMenu.push({ name: "Свод", link: "/inspector/view" });
      subMenu.push({ name: "Анализ", link: "/analyzer" });
    }
    if (check("citizen-journal:visit:r")) {
      subMenu.push({ name: "Выезды", link: "/visit/view" });
    }
    if (check("citizen-journal:object:r")) {
      subMenu.push({ name: "Объекты", link: "/object/view" });
    }
    menu.push({
      name: "Обращения",
      link: "/operator/view",
      sub: subMenu,
    });
  }
  // добавляем меню гражданина
  menu.push({
    name: "Для гражданина",
    link: "/citizen/appeal/view",
    sub: [
      { name: "Горячая линия", link: "/citizen/appeal/view" },
      {
        name: "Горячая линия (Архив до 28.11.2019)",
        link: "http://krasecology.ru/About/Hotline",
      },
      {
        name: "Карта объектов негативного воздействия",
        link: "/citizen/map/view",
      },
    ],
  });
  // добавляем личный кабинет
  if (context.logged) {
    menu.push({
      name: "Личный кабинет",
      link: "/account/info",
      sub: [
        { name: "Информация", link: "/account/info" },
        { name: "Мои обращения", link: "/citizen/personal" },
        { name: "Подать обращение", link: "/citizen/create" },
      ],
    });
  }
  if (IsMobile) {
    return <MobileMenu menu={menu} />;
  }
  const useAlter = false;
  if (useAlter) {
    return <AlterMenu menu={menu} />;
  }
  return <ComputerMenu menu={menu} />;
}

// компонент рендера мобильной версии меню
function MobileMenu(props) {
  const [show, setShow] = useState(false);
  return (
    <div className="menu-mobile">
      <button
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => setShow(true)}
      >
        <img style={{ height: "30px" }} src="/img/menu.png" alt="меню" />
        Меню
      </button>
      <MenuMobileFullScreen
        show={show}
        menu={{ name: "Меню", sub: props.menu }}
        close={() => setShow(false)}
      />
    </div>
  );
}

// компонент рендера открытого мобильного меню
class MenuMobileFullScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: [props.menu],
    };
  }
  setAM(value, names) {
    this.setState({ activeMenu: value });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.menu !== this.props.menu) {
      this.setAM([this.props.menu]);
    }
  }
  close() {
    this.setAM([this.props.menu]);
    this.props.close();
  }
  forward(value) {
    this.setAM([...this.state.activeMenu, value]);
  }
  back() {
    const activeMenu = this.state.activeMenu;
    const len = activeMenu.length;

    if (len > 1) {
      let tmp = [];
      activeMenu.forEach((e, i) => (i + 1 < len ? tmp.push(e) : null));
      this.setAM(tmp);
    } else {
      this.close();
    }
  }
  render() {
    const activeMenu = this.state.activeMenu;
    const len = activeMenu.length;
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="menu">
        <MenuMobileRender
          menu={activeMenu[len - 1]}
          close={() => this.close()}
          forward={(e) => this.forward(e)}
          back={() => this.back()}
        >
          {activeMenu.map((e, i) => (
            <i>{(i > 0 ? "/" : "") + e.name}</i>
          ))}
        </MenuMobileRender>
      </div>
    );
  }
}

// функция рендера самого списка меню
function MenuMobileRender(props) {
  return (
    <div>
      {props.children}
      {props.menu.sub.map((menuPage, ind1) => (
        <div className="sub-menu" key={ind1}>
          <b
            onClick={() => (menuPage.sub > [] ? props.forward(menuPage) : null)}
          >
            {menuPage.sub > [] ? (
              menuPage.name
            ) : menuPage.link.indexOf("http") === 0 ? (
              <a href={menuPage.link}>{menuPage.name}</a>
            ) : (
              <Link to={menuPage.link} onClick={() => props.close()}>
                {menuPage.name}
              </Link>
            )}
          </b>
        </div>
      ))}
      <b onClick={() => props.back()}>
        <span
          style={{
            border: "2px solid white",
            padding: "5px",
            borderRadius: "10px",
            marginTop: "10px",
            display: "inline-block",
          }}
        >
          Назад
        </span>
      </b>
    </div>
  );
}

// компонент рендера компьютерного меню
function ComputerMenu(props) {
  return (
    <div className="menu">
      <ul className="primary">
        {props.menu.map((menuPage, ind1) => (
          <li key={ind1}>
            <Link to={menuPage.link} className="primary">
              {menuPage.name}
            </Link>
            {menuPage.sub > [] ? (
              <ul className="sub">
                {menuPage.sub.map((subMenuPage, ind2) => (
                  <li key={ind2}>
                    {subMenuPage.link.indexOf("http") === 0 ? (
                      <a href={subMenuPage.link}>{subMenuPage.name}</a>
                    ) : (
                      <Link to={subMenuPage.link}>{subMenuPage.name}</Link>
                    )}
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
}

// компонент рендера альтернативного меню (в разработке)
function AlterMenu(props) {
  const path = useLocation().pathname;
  const [selected, setSelected] = useState(-1);
  if (!props.menu || props.menu.length < 1) {
    return null;
  }
  return (
    <span>
      {props.menu.map((menuPage, ind) =>
        ind === selected || selected === -1 ? (
          <span
            key={ind}
            style={{
              paddingLeft: "10px",
              fontWeight: menuPage.link === path ? "bold" : "normal",
            }}
            onClick={() =>
              selected === -1 ? setSelected(ind) : setSelected(-1)
            }
          >
            {!props.sub ? (
              <span>{menuPage.name}</span>
            ) : (
              <Link to={menuPage.link}>{menuPage.name}</Link>
            )}
            {ind === selected ? (
              <>
                {" > "}
                <AlterMenu menu={menuPage.sub} sub={true} />
              </>
            ) : null}
          </span>
        ) : null
      )}
    </span>
  );
}
