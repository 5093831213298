import React, { useContext } from "react";
import { DataContext, NotificationContext } from "base/context";
import {
  DateFilter,
  MultiChoiseFilter,
  NumberFilter,
  Paginator,
  TextFilter,
} from "base/filter";
import { Load } from "base/load";
import { ElementPretty, ShowIfPerm } from "base/service";
import { OperatorAppealEditRaw } from "./edit";

// страница просмотра обращений (оператором)
export class OperatorAppealViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        registrar_type: [],
        accepted: [0, 1],
        operator: [],
        stranded: [],
        tags: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
      appealID: "new",
      number: null,
      editorShow: false,
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.timeID = null;
    this.openEditor = this.openEditor.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  openEditor(id) {
    this.setState({ appealID: id, editorShow: true });
  }
  closeEditor() {
    this.get();
    this.setState({ appealID: "new", number: null,editorShow: false });
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка обращений",
      "/api/operator/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  render() {
    return (
      <div>
        <h1>Список обращений</h1>
        <hr />
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        >
          <ShowIfPerm value="citizen-journal:appeal:c">
            <button
              style={{ marginLeft: "20px", height: "30px", fontSize: "large" }}
              onClick={() => this.openEditor("new")}
            >
              <b className="text-green">Создать новое обращение</b>
            </button>
          </ShowIfPerm>
          <input
            placeholder="Номер телефона"
            style={{
              marginLeft: "20px",
              height: "24px",
              paddingLeft: "5px",
              fontSize: "large",
            }}
            value={this.state.filter.phone}
            onChange={(e) => this.updateFilter("phone", e.target.value)}
          />
          <button
            style={{ marginLeft: "5px", height: "30px", fontSize: "large" }}
            className="text-red"
            onClick={(e) => this.updateFilter("phone", "")}
          >
            Очистить
          </button>
        </Paginator>
        <hr />
        <table className="view">
          <thead className="double operator">
            <OperatorAppealViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody className="operator">
            {this.state.data.map((e, ind) => (
              <OperatorAppealViewRow
                key={ind}
                row={e}
                get={this.get}
                editor={this.openEditor}
              />
            ))}
          </tbody>
        </table>
        {this.state.editorShow ? (
          <OperatorAppealEditRaw
            id={this.state.appealID}
            close={this.closeEditor}
          />
        ) : null}
      </div>
    );
  }
}
OperatorAppealViewPage.contextType = NotificationContext;

// шапка таблицы обращений
function OperatorAppealViewHead(props) {
  const context = useContext(DataContext);
  return (
    <>
      <tr>
        <td>Действия</td>
        <td>Номер заявки</td>
        <td>Время занесения в базу</td>
        <td>Адрес нарушения</td>
        <td>Адрес заявителя</td>
        <td>ФИО заявителя</td>
        <td>Район</td>
        <td>Микрорайон</td>
        <td>Телефон заявителя</td>
        <td>Причина звонка</td>
        <td>Тип подавшего заявку</td>
        <td>Прошла ли заявка модерацию</td>
        <td>Тэги</td>
        <td>Оператор</td>
        <td>Инспектор</td>
        <td>Заметка</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <NumberFilter
            value={props.filter.number}
            onChange={(e) => props.updateFilter("number", e)}
          />
        </td>
        <td>
          <DateFilter
            value={props.filter.registration}
            onChange={(e) => props.updateFilter("registration", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.address}
            onChange={(e) => props.updateFilter("address", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.residence}
            onChange={(e) => props.updateFilter("residence", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.fullname}
            onChange={(e) => props.updateFilter("fullname", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.district}
            onChange={(e) => props.updateFilter("district", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.microdistrict}
            onChange={(e) => props.updateFilter("microdistrict", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.phone}
            onChange={(e) => props.updateFilter("phone", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.reason}
            onChange={(e) => props.updateFilter("reason", e)}
          />
        </td>
        <td>
          <MultiChoiseFilter
            value={props.filter.registrar_type}
            info={context.registrarType}
            onChange={(e) => props.updateFilter("registrar_type", e)}
          />
        </td>
        <td>
          <MultiChoiseFilter
            value={props.filter.accepted}
            info={context.accepted}
            onChange={(e) => props.updateFilter("accepted", e)}
          />
        </td>
        <td>
          <MultiChoiseFilter
            value={props.filter.tags}
            info={context.tagsToFilter}
            onChange={(e) => props.updateFilter("tags", e)}
          />
        </td>
        <td>
          <MultiChoiseFilter
            value={props.filter.operator}
            info={context.operatorsToFilter}
            onChange={(e) => props.updateFilter("operator", e)}
          />
        </td>
        <td>
          <MultiChoiseFilter
            value={props.filter.stranded}
            info={context.inspectorsToFilter}
            onChange={(e) => props.updateFilter("stranded", e)}
          />
        </td>
        <td>
          <TextFilter
            value={props.filter.note}
            onChange={(e) => props.updateFilter("note", e)}
          />
        </td>
      </tr>
    </>
  );
}

// строка таблицы обращений
class OperatorAppealViewRow extends React.Component {
  constructor(props) {
    super(props);
    this.del = this.del.bind(this);
    this.copy = this.copy.bind(this);
    this.moderate = this.moderate.bind(this);
  }
  del() {
    if (
      window.confirm("Это действие удалит обращение, продолжить?") === false
    ) {
      return;
    }
    new Load(
      this.context,
      "Удаление обращения",
      "/api/operator/delete",
      JSON.stringify(this.props.row.id)
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.props.get());
  }
  copy() {
    new Load(
      this.context,
      "Получение данных об обращении",
      "/api/operator/get",
      JSON.stringify(this.props.row.id)
    )
      .Load()
      .then((data) => {
        let tmp = {
          data: data.data,
          note: data.note,
          tags: data.tags,
        };
        localStorage.setItem("appeal_editor_data", JSON.stringify(tmp));
      });
  }
  moderate(accept) {
    new Load(
      this.context,
      "Изменение статуса модерации",
      "/api/operator/moderate",
      { id: this.props.row.id, accept: accept }
    )
      .SetOk()
      .SetErr()
      .Load()
      .then(() => this.props.get());
  }
  render() {
    const row = this.props.row;
    const type =
      row.accepted === 2
        ? "decline"
        : row.accepted === 1
        ? "accept"
        : "process";
    return (
      <tr type={type}>
        <td>
          <ShowIfPerm value="citizen-journal:appeal:operator:u">
            <button onClick={this.copy} title="копировать данные обращения">
              <img
                src="/img/copy.png"
                className="menu"
                alt="копировать данные обращения"
              />
            </button>
            <button
              onClick={() => this.props.editor(row.id)}
              title="открыть в редакторе"
            >
              <img
                src="/img/edit.png"
                className="menu"
                alt="открыть в редакторе"
              />
            </button>
          </ShowIfPerm>
          <ShowIfPerm value="citizen-journal:appeal:d">
            <button onClick={this.del} title="удалить">
              <img src="/img/delete.png" className="menu" alt="удаление" />
            </button>
          </ShowIfPerm>
          <ShowIfPerm value="citizen-journal:appeal:moderator:u">
            {row.accepted === 0 ? (
              <>
                <button
                  onClick={() => this.moderate(true)}
                  title="принять обращение"
                >
                  <img
                    src="/img/accept.png"
                    className="menu"
                    alt="принять обращение"
                  />
                </button>
                <button
                  onClick={() => this.moderate(false)}
                  title="отклонить обращение"
                >
                  <img
                    src="/img/decline.png"
                    className="menu"
                    alt="отклонить обращение"
                  />
                </button>
              </>
            ) : null}
          </ShowIfPerm>
        </td>
        <td className="centred">{row.number}</td>
        <td>
          <ElementPretty value={row.registration} type="date" />
        </td>
        <td className="centred">{row.data.address}</td>
        <td className="centred">{row.data.residence}</td>
        <td className="centred">{row.data.fullname}</td>
        <td className="centred">{row.data.district}</td>
        <td className="centred">{row.data.microdistrict}</td>
        <td className="centred">{row.data.phone}</td>
        <td className="centred">{row.data.reason}</td>
        <td className="centred">
          <ElementPretty value={row.registrar_type} type="registrar" />
        </td>
        <td className="centred">
          <ElementPretty value={row.accepted} type="accepted" />
        </td>
        <td className="centred">
          <ElementPretty value={row.tags} type="join" />
        </td>
        <td className="centred">{row.operator}</td>
        <td className="centred">{row.inspector}</td>
        <td className="centred">{row.note}</td>
      </tr>
    );
  }
}
OperatorAppealViewRow.contextType = NotificationContext;
