import { InspectorAppealViewHead, InspectorAppealViewRow } from "./view";

// функция для рендера фкладки с дочерними заявками
export function ViewChilds(props) {
  return (
    <div style={{ marginTop: "10px" }}>
      <table className="view">
        <thead className="triple sub-info">
          <InspectorAppealViewHead disableFilter={true} />
        </thead>
        <tbody>
          {props.value.map((e, ind) => (
            <InspectorAppealViewRow key={ind} row={e} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
