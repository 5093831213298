import React from "react";
import { NotificationContext } from "base/context";
import { Load } from "base/load";

// страница редактирования списка сотрудников
export class StaffPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cloned_id: "",
    };
    this.get = this.get.bind(this);
    this.clone = this.clone.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  get() {
    new Load(this.context, "Получение списка сотрудников", "/api/info/staff")
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.sort((a, b) =>
            a.fullname < b.fullname ? -1 : a.fullname > b.fullname ? 1 : 0
          ),
        });
      });
  }
  clone() {
    new Load(
      this.context,
      "Клонирование учетной записи",
      "/api/admin/clone-employee",
      JSON.stringify(this.state.cloned_id)
    )
      .SetErr()
      .SetOk()
      .Load()
      .then(() => {
        this.get();
      });
  }
  render() {
    return (
      <div>
        <h1>Редактирование сотрудников журналов регистрации граждан</h1>
        <input
          value={this.state.cloned_id}
          onChange={(e) => this.setState({ cloned_id: e.target.value })}
          placeholder="ID сервера авторизации"
        />
        <button onClick={this.clone}>Клонировать</button>
        <table>
          <thead>
            <tr>
              <td>внутренний ид сотрудника</td>
              <td>ид аккаунта сотрудника в системе авторизации</td>
              <td>полное имя сотрудника (ФИО)</td>
              <td>краткое имя сотрудника фамилия и инициалы</td>
              <td>
                является оператором (влияет на отображение в выборе сотрудника)
              </td>
              <td>
                является инспектором (влияет на отображение в выборе сотрудника)
              </td>
              <td>отключает отображение сотрудника в выборе</td>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((e) => (
              <StaffRow employee={e} key={e.id} get={this.get} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
StaffPage.contextType = NotificationContext;

// компонент рендера сотрудника
class StaffRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.employee.id,
      auth_id: props.employee.auth_id,
      fullname: props.employee.fullname,
      shortname: props.employee.shortname,
      is_operator: props.employee.is_operator,
      is_inspector: props.employee.is_inspector,
      dont_show: props.employee.dont_show,
      update: false,
    };
    this.update = this.update.bind(this);
  }
  update() {
    new Load(
      this.context,
      "Обновление данных сотрудника",
      "/api/admin/edit-employee",
      this.state
    )
      .SetErr()
      .SetOk()
      .Load()
      .then(() => {
        this.props.get();
      });
  }
  render() {
    return (
      <tr>
        <td>{this.state.id}</td>
        <td>
          <input
            value={this.state.auth_id}
            onChange={(e) =>
              this.setState({ auth_id: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            value={this.state.fullname}
            onChange={(e) =>
              this.setState({ fullname: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            value={this.state.shortname}
            onChange={(e) =>
              this.setState({ shortname: e.target.value, update: true })
            }
          />
        </td>
        <td>
          <input
            checked={this.state.is_operator}
            onChange={(e) =>
              this.setState((state) => ({
                is_operator: !state.is_operator,
                update: true,
              }))
            }
            type="checkbox"
          />
        </td>
        <td>
          <input
            checked={this.state.is_inspector}
            onChange={(e) =>
              this.setState((state) => ({
                is_inspector: !state.is_inspector,
                update: true,
              }))
            }
            type="checkbox"
          />
        </td>
        <td>
          <input
            checked={this.state.dont_show}
            onChange={(e) =>
              this.setState((state) => ({
                dont_show: !state.dont_show,
                update: true,
              }))
            }
            type="checkbox"
          />
        </td>
        <td>
          {this.state.update ? (
            <button className="text-green" onClick={this.update}>
              Сохранить
            </button>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }
}
StaffRow.contextType = NotificationContext;
