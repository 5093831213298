import React from "react";
import { useParams } from "react-router-dom";
import { NotificationContext } from "base/context";
import { Load } from "base/load";
import { ChoiseInnerDataPicker, ElementPretty, FakeButton } from "base/service";

// страница редактора объекта ОНВОС
export function ObjectEditPage() {
  const { id } = useParams();
  return <ObjectEditPageRaw id={id} />;
}

// редактор объекта ОНВОС
export class ObjectEditPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
    this.save = this.save.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.get(this.props.id);
    }
  }
  componentDidMount() {
    this.get(this.props.id);
  }
  get(id) {
    if (id === "new") {
      this.setState({
        data: {
          registration: new Date().toJSON(),
          nmu: false,
          temporary: false,
        },
      });
      return;
    }
    new Load(
      this.context,
      "Получение объекта",
      "/api/object/get",
      JSON.stringify(id)
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ data: data });
      });
  }
  save() {
    const id = this.state.data.id;
    new Load(
      this.context,
      "Сохранение объекта",
      "/api/object/" + (id ? "update" : "create"),
      this.state.data
    )
      .SetOk()
      .SetErr()
      .Load()
      .then((data) => this.get(id ? id : data));
  }
  update(fieldName, value) {
    let tmp = { ...this.state.data };
    tmp[fieldName] = value;
    this.setState({ data: tmp });
  }
  render() {
    const data = this.state.data;
    const header =
      data.id !== "new" && data.id !== undefined
        ? "Объект #" + data.id
        : "Новый объект";
    return (
      <div>
        <h1 className="centred">{header}</h1>
        <table>
          <tbody>
            <tr>
              <td className="info">ID</td>
              <td className="data">{data.id}</td>
            </tr>
            <tr>
              <td className="info">Зарегестрирован</td>
              <td className="data">
                <ElementPretty value={data.registration} type="date" />
              </td>
            </tr>
            <tr>
              <td className="info">Наименование хозяйствующего субъекта</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.name}
                  onChange={(e) => this.update("name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Наименование объекта негативного воздействия
              </td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.object_name}
                  onChange={(e) => this.update("object_name", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">ИНН</td>
              <td className="data">
                <input
                  className="stdin"
                  value={data.inn}
                  onChange={(e) => this.update("inn", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Адрес местонахождения эксплуатируемого объекта негативного
                воздействия
              </td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.address}
                  onChange={(e) => this.update("address", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Широта</td>
              <td className="data">
                <input
                  type="number"
                  step={0.01}
                  className="stdin"
                  value={data.lat}
                  onChange={(e) =>
                    this.update("lat", parseFloat(e.target.value))
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="info">Долгота</td>
              <td className="data">
                <input
                  type="number"
                  step={0.01}
                  className="stdin"
                  value={data.lon}
                  onChange={(e) =>
                    this.update("lon", parseFloat(e.target.value))
                  }
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Категория объекта негативного воздействия
              </td>
              <td className="data">
                <ChoiseInnerDataPicker
                  value={data.category}
                  onChange={(e) => this.update("category", e)}
                  type="category"
                  className="stdin"
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Поднадзорность (региональный/федеральный надзор)
              </td>
              <td className="data">
                <ChoiseInnerDataPicker
                  value={data.oversight}
                  onChange={(e) => this.update("oversight", e)}
                  type="oversight"
                  className="stdin"
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Включен в перечень предприятий, обязанных проводить мероприятия
                в период НМУ
              </td>
              <td className="data">
                <input
                  className="stdin"
                  type="checkbox"
                  checked={data.nmu}
                  onChange={(e) => this.update("nmu", e.target.checked)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">
                Временный объект (не отображается на карте)
              </td>
              <td className="data">
                <input
                  className="stdin"
                  type="checkbox"
                  checked={data.temporary}
                  onChange={(e) => this.update("temporary", e.target.checked)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Состоит на учете ОНВОС</td>
              <td className="data">
                <input
                  className="stdin"
                  type="checkbox"
                  checked={data.registered}
                  onChange={(e) => this.update("registered", e.target.checked)}
                />
              </td>
            </tr>
            <tr>
              <td className="info">Заметка</td>
              <td className="data">
                <textarea
                  className="stdin"
                  value={data.note}
                  onChange={(e) => this.update("note", e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="centred">
                <button className="text-green" onClick={this.save}>
                  Сохранить
                </button>
                <FakeButton url={"/object/view"}>
                  <span className="text-red">Отмена</span>
                </FakeButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
ObjectEditPageRaw.contextType = NotificationContext;
