import React from "react";
import { NotificationContext, ZeroID } from "base/context";
import { Paginator } from "base/filter";
import { Load } from "base/load";
import { InspectorAppealViewHead, InspectorAppealViewRow } from "./view";

// компонент выбора родителя для редактора свода
export class SelectParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageInfo: {
        current: 0,
        len: 20,
        count: 0,
        items: 0,
        items_total: 0,
      },
      filter: {
        in_work: true,
        has_parent: 2,
        number: null,
        inspector_ids: [],
        registrar_type: [],
        accepted: [1],
        tags: [],
        phase_id: [],
        phase_public_id: [],
        page: {
          count: 20,
          offset: 0,
          reverse: true,
        },
      },
    };
    this.get = this.get.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.timeID = null;
  }
  componentDidMount() {
    this.get();
  }
  updateFilter(fieldName, value) {
    let tmp = { ...this.state.filter };
    tmp[fieldName] = value;
    this.setState({ filter: tmp });
    this.timer();
  }
  timer() {
    if (this.timeID != null) {
      clearTimeout(this.timeID);
    }
    this.timeID = setTimeout(this.get, 500);
  }
  get() {
    clearTimeout(this.timeID);
    new Load(
      this.context,
      "Получение списка обращений",
      "/api/inspector/filter",
      this.state.filter
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({
          data: data.data,
          pageInfo: data.page,
        });
      });
  }
  render() {
    return (
      <div>
        <Paginator
          info={this.state.pageInfo}
          get={this.get}
          value={this.state.filter.page}
          onChange={(e) => this.updateFilter("page", e)}
          height={8}
        >
          {this.props.value !== ZeroID ? (
            <button
              className="text-red"
              onClick={() => this.props.onChange(ZeroID)}
            >
              <b>УДАЛИТЬ РОДИТЕЛЯ</b>
            </button>
          ) : null}
        </Paginator>
        <hr />
        <table className="view">
          <thead className="double sub-info">
            <InspectorAppealViewHead
              filter={this.state.filter}
              updateFilter={this.updateFilter}
            />
          </thead>
          <tbody className="sub-info">
            {this.state.data.map((e, ind) => (
              <InspectorAppealViewRow
                key={ind}
                row={e}
                get={this.get}
                smode={true}
                onChange={(e) => this.props.onChange(e)}
                id={this.props.id}
                value={this.props.value}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
SelectParent.contextType = NotificationContext;
