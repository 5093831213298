import React from "react";
import { ElementPretty, FakeButton } from "base/service";

// функция для вкладки отображения данных родительской заявки
export function ViewParent(props) {
  const parent = props.value;
  if (!parent) {
    return null;
  }
  const hasCache = parent.cache ? true : false;
  const hasLastStage = parent.cache && parent.cache.last_stage;
  const url = "/inspector/edit/" + parent.id;
  return (
    <table>
      <tbody>
        <tr>
          <td className="info">ID</td>
          <td className="data">
            <FakeButton url={url}>
              <span className="text-gray">{parent.id}</span>
            </FakeButton>
          </td>
        </tr>
        <tr>
          <td className="info">Инспектора</td>
          <td className="data">{parent.inspectors}</td>
        </tr>
        <tr>
          <td className="info">Дочерняя заявка</td>
          <td className="data">
            <ElementPretty value={parent.has_parent} type="bool" />
          </td>
        </tr>
        <tr>
          <td className="info">Время занесения в базу</td>
          <td className="data">
            <ElementPretty value={parent.registration} type="date" />
          </td>
        </tr>
        {parent.data !== undefined ? (
          <>
            <tr>
              <td className="info">Адрес нарушения</td>
              <td className="data">{parent.data.address}</td>
            </tr>
            <tr>
              <td className="info">Объект нарушения</td>
              <td className="data">{parent.data.object_name}</td>
            </tr>
            <tr>
              <td className="info">Адрес заявителя</td>
              <td className="data">{parent.data.residence}</td>
            </tr>
            <tr>
              <td className="info">ФИО заявителя</td>
              <td className="data">{parent.data.fullname}</td>
            </tr>
            <tr>
              <td className="info">Район</td>
              <td className="data">{parent.data.district}</td>
            </tr>
            <tr>
              <td className="info">Микрорайон</td>
              <td className="data">{parent.data.microdistrict}</td>
            </tr>
            <tr>
              <td className="info">Телефон заявителя</td>
              <td className="data">{parent.data.phone}</td>
            </tr>
            <tr>
              <td className="info">Причина звонка</td>
              <td className="data">{parent.data.reason}</td>
            </tr>
          </>
        ) : null}
        <tr>
          <td className="info">Тип подавшего заявку</td>
          <td className="data">{parent.registrar}</td>
        </tr>
        <tr>
          <td className="info">Прошла ли заявка модерацию</td>
          <td className="data">{parent.accepted_text}</td>
        </tr>
        <tr>
          <td className="info">Тэги</td>
          <td className="data">
            <ElementPretty value={parent.tags} type="join" />
          </td>
        </tr>
        <tr>
          <td className="info">Имеет этапы</td>
          <td className="data">
            {hasCache ? (
              <ElementPretty value={parent.cache.has_stage} type="bool" />
            ) : null}
          </td>
        </tr>
        <tr>
          <td className="info">Количество выездов</td>
          <td className="data">{hasCache ? parent.cache.visit_count : ""}</td>
        </tr>
        <tr>
          <td className="info">Последний этап</td>
          <td className="data">{hasCache ? parent.cache.phase : ""}</td>
        </tr>
        <tr>
          <td className="info">Последний этап гражданин</td>
          <td className="data">{hasCache ? parent.cache.phase_public : ""}</td>
        </tr>
        <tr>
          <td className="info">Крайний срок</td>
          <td className="data">
            {hasLastStage && parent.cache.last_stage.notification ? (
              <ElementPretty
                value={parent.cache.last_stage.notification_time}
                type="date"
              />
            ) : null}
          </td>
        </tr>
        <tr>
          <td className="info">Количество дочерних заявок</td>
          <td className="data">
            {parent.child_ids ? parent.child_ids.length : 0}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
