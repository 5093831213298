import { useContext, useState } from "react";
import { AccountContext, NotificationContext } from "base/context";
import { Load } from "base/load";
import { useHistory } from "react-router-dom";

// страница для подачи заявки (интернет приемная)
export function CitizenServeAppealPage() {
  const account = useContext(AccountContext);
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState({
    address: "",
    residence: "",
    fullname: account.fullname,
    district: "",
    microdistrict: "",
    phone: account.phone,
    reason: "",
    object_name: "",
  });
  const context = useContext(NotificationContext);
  const history = useHistory();
  const save = () => {
    if (
      !confirm ||
      !data.fullname ||
      !data.address ||
      !data.district ||
      !data.phone ||
      !data.reason
    ) {
      alert("Не заполнены обязательные поля!");
      return;
    }
    new Load(context, "Подача обращения", "/api/citizen/create", data)
      .SetOk("успешно", null, (id) => "Код вашей заявки: " + id)
      .SetErr()
      .Load()
      .then(() => {
        history.push("/citizen/personal");
      });
  };
  const update = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };
  const red = <span style={{ color: "red" }}>*</span>;
  return (
    <div className="centred">
      <h1>Подача обращения</h1>
      <hr />
      <table className="inline-block">
        <tbody>
          <tr>
            <td>
              <input
                placeholder="ФИО"
                className="stdin"
                value={data.fullname}
                onChange={(e) => update("fullname", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Телефон"
                className="stdin"
                value={data.phone}
                onChange={(e) => update("phone", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Адрес проживания"
                className="stdin"
                value={data.residence}
                onChange={(e) => update("residence", e.target.value)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Адрес нарушения"
                className="stdin"
                value={data.address}
                onChange={(e) => update("address", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Район нарушения"
                className="stdin"
                value={data.district}
                onChange={(e) => update("district", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Микрорайон нарушения"
                className="stdin"
                value={data.microdistrict}
                onChange={(e) => update("microdistrict", e.target.value)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <textarea
                placeholder="Причина обращения"
                className="stdin"
                value={data.reason}
                onChange={(e) => update("reason", e.target.value)}
              />
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td>
              <input
                placeholder="Наименование объекта"
                className="stdin"
                value={data.object_name}
                onChange={(e) => update("object_name", e.target.value)}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <label>
                <input
                  type="checkbox"
                  checked={confirm}
                  onChange={(e) => setConfirm(e.target.checked)}
                />
                Согласие на обработку персональных данных
              </label>
            </td>
            <td>{red}</td>
          </tr>
          <tr>
            <td colSpan={2}>{red} - поля обязательные для заполнения</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <button className="text-green" onClick={save}>
                Отправить
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
