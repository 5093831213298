// ИД сообщения
let lastID = 0;

// сообщения для отображения в уведомлениях
export class Message {
  constructor(header = "", message = "") {
    this.id = ++lastID; // ид сообщения
    // this.show = true; // отображать сообщение
    this.header = header; // заголовок
    this.message = message; // основной текст
    this.clk = null; // функция вызывающаяся при закрытии сообщения
    this.tp = 0; // 0 - информационное сообщение 1 - сообщение о успехе 2 - сообщение о ошибке 3 - сообщение о загрузке
    this.closeTimeout = 0; // время автоматического закрытия окна в секундах, если 0 то игнорируется
    this.applied = false; // добавлен ли в контекст (при добавлении блокирует изменения)
    this.del = () => {}; // функция удаления из контекста
    this.Close = this.Close.bind(this);
  }
  Close() {
    this.del(this.id);
    if (this.clk != null) {
      this.clk();
    }
  }
  SetHeader(header) {
    if (this.applied) {
      return this;
    }
    this.header = header;
    return this;
  }
  SetMessage(message) {
    if (this.applied) {
      return this;
    }
    this.message = message;
    return this;
  }
  SetClickFunction(clk) {
    if (this.applied) {
      return this;
    }
    this.clk = clk;
    return this;
  }
  // устанавливает тип сообщения о ошибке/успехе
  SetSuccessType(ok = false) {
    if (this.applied) {
      return this;
    }
    if (ok) {
      this.tp = 1;
    } else {
      this.tp = 2;
    }
    return this;
  }
  // устанавливает тип сообщения загрузка
  SetLoadType() {
    if (this.applied) {
      return this;
    }
    this.tp = 3;
    return this;
  }
  // устанавливает таймер закрытия сообщения
  SetCloseTimeout(t) {
    if (this.applied) {
      return this;
    }
    this.closeTimeout = t;
    return this;
  }
  // добавляет сообщение в контекст
  Apply(context) {
    this.applied = true;
    this.del = context.delNotification;
    context.addNotification(this);
    if (this.closeTimeout > 0) {
      setTimeout(() => this.Close(), this.closeTimeout * 1000);
    }
  }
}
