import React from "react";
import { HashRouter } from "react-router-dom";
import {
  AccountContext,
  AuthContext,
  DataContext,
  NotificationContext,
} from "./base/context.js";
import { Load } from "./base/load.js";
import { Header } from "./header/header.js";
import { Notification } from "./notification/notification.js";
import { Pages } from "./pages/pages.js";

// время обновления данных
const updatePeriod = 10 * 60 * 1000; // время автообновления данных

// главный компонент приложения
export class App extends React.Component {
  constructor(props) {
    super(props);
    // привязка ключевых функций
    this.addNotification = this.addNotification.bind(this);
    this.delNotification = this.delNotification.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.update = this.update.bind(this);
    // установка глобального состояния
    this.state = {
      notification: {
        addNotification: this.addNotification,
        delNotification: this.delNotification,
      },
      notificationMessages: [],
      account: this.getAccount(),
      auth: {
        login: this.login,
        logout: this.logout,
      },
      globalData: this.getEmptyGlobalData(),
    };
  }
  componentDidMount() {
    this.update();
    this.updaterID = setInterval(this.update, updatePeriod);
  }
  componentWillUnmount() {
    clearInterval(this.updaterID);
  }
  addNotification(message) {
    this.setState((state) => {
      let messages = [...state.notificationMessages];
      messages.push(message);
      return { notificationMessages: messages };
    });
  }
  delNotification(id) {
    this.setState((state) => {
      let messages = state.notificationMessages;
      return { notificationMessages: messages.filter((e) => e.id !== id) };
    });
  }
  getEmptyGlobalData() {
    return {
      staff: [], // весь персонал
      tags: [], // эко теги
      phases: [], // фазы свода
      phasesPublic: [], // фазы для гражданина
      inspectors: [], // инспектора
      operators: [], // операторы
      inspectorsToFilter: [], // инспектора для фильтра
      operatorsToFilter: [], // операторы для фильтра
      tagsToFilter: [], // тэги для фильтра
      phasesToFilter: [], // фазы свода для фильтра
      phasesPublicToFilter: [], // фазы для гражданина, для фильтра
      category: [], // категории объектов негативного воздействия
      oversight: [], // категории ОНВОС
      registrarType: [], // типы подавшего заявку
      accepted: [], // типы статуса заявки
    };
  }
  getAccount(info = null) {
    let acc = {
      accountInfo: {},
      fullname: "",
      phone: "",
      logged: false,
      checkPerm: () => {
        return false;
      },
    };
    if (info != null) {
      acc.logged = true;
      acc.accountInfo = info;
      if (acc.accountInfo.permissions) {
        acc.checkPerm = (code) => acc.accountInfo.permissions.includes(code);
      }
      if (info.name) {
        acc.fullname += info.name.lastname;
        if (info.name.firstname) {
          acc.fullname += " " + info.name.firstname;
        }
        if (info.name.patronymic) {
          acc.fullname += " " + info.name.patronymic;
        }
      }
      if (info.phone) {
        acc.phone = info.phone.data;
      }
    }
    return acc;
  }
  login(login, pass) {
    return new Promise((resolve, reject) => {
      new Load(this.state.notification, "Авторизация", "/api/auth/login", {
        login: login,
        pass: pass,
      })
        .SetOk("Успешно")
        .SetErr("Ошибка авторизации")
        .Load()
        .then(() => {
          this.update();
          resolve();
        })
        .catch((err) => reject(err));
    });
  }
  logout() {
    new Load(this.state.notification, "Выход", "/api/auth/logout")
      .SetOk("Успешно")
      .SetErr("Ошибка выхода")
      .Load()
      .then(() => {
        this.update();
      });
  }
  update() {
    new Load(
      this.state.notification,
      "Получение информамации о пользователе",
      "/api/account/info"
    )
      .Load()
      .then((data) => {
        let account = this.getAccount(data);
        this.loadGlobalData(account);
      })
      .catch(() => {
        this.setState({
          account: this.getAccount(null),
          globalData: this.getEmptyGlobalData(),
        });
      });
  }
  loadGlobalData(account) {
    let globalData = this.getEmptyGlobalData();
    const hasAppealRead = account.checkPerm("citizen-journal:appeal:r");
    const hasObjectRead = account.checkPerm("citizen-journal:object:r");
    const loads = [
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка сотрудников",
            "/api/info/staff"
          ).Load()
        : [],
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка тэгов",
            "/api/info/tags"
          ).Load()
        : [],
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка этапов",
            "/api/info/phases"
          ).Load()
        : [],
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка публичных этапов",
            "/api/info/public-phases"
          ).Load()
        : [],
      hasObjectRead
        ? new Load(
            this.state.notification,
            "Получение списка категорий объекта ОНВОС",
            "/api/info/category"
          ).Load()
        : [],
      hasObjectRead
        ? new Load(
            this.state.notification,
            "Получение списка поднадзорностей объекта ОНВОС",
            "/api/info/oversight"
          ).Load()
        : [],
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка типов подавшего заявку",
            "/api/info/registrar-type"
          ).Load()
        : [],
      hasAppealRead
        ? new Load(
            this.state.notification,
            "Получение списка статусов модерации",
            "/api/info/accepted"
          ).Load()
        : [],
    ];
    Promise.all(loads)
      .then(
        ([
          staff,
          tags,
          phases,
          phasesPublic,
          category,
          oversight,
          registrarType,
          accepted,
        ]) => {
          globalData.staff = staff.sort((a, b) =>
            a.fullname < b.fullname ? -1 : a.fullname > b.fullname ? 1 : 0
          );
          globalData.tags = tags;
          globalData.phases = phases;
          globalData.phasesPublic = phasesPublic;
          globalData.inspectors = globalData.staff.filter(
            (e) => e.is_inspector
          );
          globalData.operators = globalData.staff.filter((e) => e.is_operator);
          let staffToFilter = globalData.staff.map((e) => ({
            k: e.id,
            v: e.fullname,
            is_inspector: e.is_inspector,
            is_operator: e.is_operator,
          }));
          globalData.inspectorsToFilter = staffToFilter.filter(
            (e) => e.is_inspector
          );
          globalData.operatorsToFilter = staffToFilter.filter(
            (e) => e.is_operator
          );
          globalData.tagsToFilter = globalData.tags.map((e) => ({
            k: e,
            v: e,
          }));
          globalData.phasesToFilter = globalData.phases.map((e) => ({
            k: e.id,
            v: e.name,
          }));
          globalData.phasesPublicToFilter = globalData.phasesPublic.map(
            (e) => ({
              k: e.id,
              v: e.name,
            })
          );
          globalData.category = category;
          globalData.oversight = oversight;
          globalData.registrarType = registrarType;
          globalData.accepted = accepted;
          this.setState({
            account: account,
            globalData: globalData,
          });
        }
      )
      .catch(() => {
        this.setState({
          account: account,
          globalData: this.getEmptyGlobalData(),
        });
      });
  }
  render() {
    return (
      <HashRouter>
        <NotificationContext.Provider value={this.state.notification}>
          <AccountContext.Provider value={this.state.account}>
            <AuthContext.Provider value={this.state.auth}>
              <DataContext.Provider value={this.state.globalData}>
                <Header />
                <Pages />
                <Notification messages={this.state.notificationMessages} />
              </DataContext.Provider>
            </AuthContext.Provider>
          </AccountContext.Provider>
        </NotificationContext.Provider>
      </HashRouter>
    );
  }
}
