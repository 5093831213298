import React from "react";
import { AccountContext } from "base/context.js";

// компонент рендера страницы информации об аккаунте
export class AccountInfoPage extends React.Component {
  render() {
    const info = this.context.accountInfo;
    return (
      <div className="account-info">
        <h1>Информация об аккаунте</h1>
        {info && info.id ? (
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{info.id}</td>
              </tr>
              <tr>
                <td>Логин</td>
                <td>{info.login}</td>
              </tr>
              <tr>
                <td>Почта</td>
                <td>
                  <HasVerified value={info.email} />
                </td>
              </tr>
              <tr>
                <td>Телефон</td>
                <td>
                  <HasVerified value={info.phone} />
                </td>
              </tr>
              <tr>
                <td>Организация</td>
                <td>{info.organisation}</td>
              </tr>
              <tr>
                <td>Фамилия</td>
                <td>{info.name.lastname}</td>
              </tr>
              <tr>
                <td>Имя</td>
                <td>{info.name.firstname}</td>
              </tr>
              <tr>
                <td>Отчество</td>
                <td>{info.name.patronymic}</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>{info.last_action}</td>
              </tr>
              <tr>
                <td>Последние действия в сессии</td>
                <td>{info.last_check}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    );
  }
}
AccountInfoPage.contextType = AccountContext;

// функция рендера почты/телефона (подтвержден и тд)
function HasVerified(props) {
  return (
    <div className="has-verified">
      {props.value && props.value.exist ? (
        <div
          className={props.value.verified ? "ok" : "fail"}
          title={props.value.verified ? "подтверждено" : "не подтверждено"}
        >
          <b>{props.value.data}</b>
        </div>
      ) : (
        <div className="not">не указано</div>
      )}
    </div>
  );
}
