import React, { useState } from "react";
import { IsMobile, NotificationContext } from "base/context";
import { Load } from "base/load";
import { ElementPretty } from "base/service";

// страница просмотра подданых жалоб гражданином
export class CitizenPersonalViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.get = this.get.bind(this);
  }
  componentDidMount() {
    this.get();
  }
  get() {
    new Load(
      this.context,
      "Получение списка обращений",
      "/api/citizen/personal"
    )
      .SetErr()
      .Load()
      .then((data) => {
        this.setState({ data: data });
      });
  }
  render() {
    return (
      <div>
        <h1>Список моих обращений</h1>
        <hr />
        <table className="view" style={{ width: "100%" }}>
          <thead className="single citizen">
            <tr>
              <td>Номер обращения</td>
              <td>Время регистрации</td>
              <td>Статус</td>
              <td>Адрес</td>
              {IsMobile ? null : (
                <>
                  <td>Объект загрязнения</td>
                  <td>Причина обращения</td>
                  <td>Результат</td>
                  <td>Нарушения</td>
                  <td>Перенаправление информации по компетенции</td>
                </>
              )}
            </tr>
          </thead>
          <tbody className="citizen">
            {this.state.data.map((e, ind) => (
              <CitizenAppealViewRow key={ind} row={e} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
CitizenPersonalViewPage.contextType = NotificationContext;

// функция для рендера строки обращения
function CitizenAppealViewRow(props) {
  const lastStage =
    props.row.cache && props.row.cache.last_stage
      ? props.row.cache.last_stage
      : {
          result: "",
          excesses: "",
          info_redirect: "",
        };
  const [show, setShow] = useState(false);
  const click = () => {
    setShow(true);
  };
  const address = props.row.data.address;
  const statusDeny = props.row.accepted === 2;
  const statusModerate = props.row.accepted === 0;
  const phase =
    (statusDeny
      ? "Не прошла проверку модератором"
      : statusModerate
      ? "На проверке модератора"
      : props.row.cache
      ? props.row.cache.phase_public
      : "") || "Принята";
  if (IsMobile && show) {
    return (
      <tr>
        <td>
          <div className="full-screen">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td className="info">Номер обращения</td>
                    <td className="data">{props.row.number}</td>
                  </tr>
                  <tr>
                    <td className="info">Время регистрации</td>
                    <td className="data">
                      <ElementPretty
                        value={props.row.registration}
                        type="date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="info">Адрес</td>
                    <td className="data">{address}</td>
                  </tr>
                  <tr>
                    <td className="info">Объект загрязнения</td>
                    <td className="data">{props.row.data.object_name}</td>
                  </tr>
                  <tr>
                    <td className="info">Причина обращения</td>
                    <td className="data">{props.row.data.reason}</td>
                  </tr>
                  <tr>
                    <td className="info">Статус</td>
                    <td className="data">{phase}</td>
                  </tr>
                  {statusDeny || statusModerate ? null : (
                    <>
                      <tr>
                        <td className="info">Результат</td>
                        <td className="data">{lastStage.result}</td>
                      </tr>
                      <tr>
                        <td className="info">Нарушения</td>
                        <td className="data">{lastStage.excesses}</td>
                      </tr>
                      <tr>
                        <td className="info">
                          Перенаправление информации по компетенции
                        </td>
                        <td className="data">{lastStage.info_redirect}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td colSpan="2">
                      <button
                        className="text-red"
                        onClick={() => setShow(false)}
                        style={{ width: "100%" }}
                      >
                        Закрыть
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }
  return (
    <tr onClick={click}>
      <td className="centred">{props.row.number}</td>
      <td>
        <ElementPretty value={props.row.registration} type="date" />
      </td>
      <td className="centred">{phase}</td>
      <td className="centred">{address}</td>
      {IsMobile ? null : (
        <>
          <td className="centred">{props.row.data.object_name}</td>
          <td className="centred">{props.row.data.reason}</td>
          <td className="centred">{lastStage.result}</td>
          <td className="centred">{lastStage.excesses}</td>
          <td className="centred">{lastStage.info_redirect}</td>
        </>
      )}
    </tr>
  );
}
